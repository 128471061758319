<template>
  <div>
    <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
    <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="菜单权限表"
                    :showData="formInfo.editData"
                    :rules="rules" @submitAdd="submitAdd" @submitEdit="editMenu" ref="baseForm" defaultEmpty>
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <!-- 父菜单ID -->
        <el-form-item :label="columnInfo.parentId.label" prop="parentId">
          <select-tree v-model="ruleForm.parentId" :options="[{id:'0',name:'主目录',childrenList:tableData}]"
                       :props="{value: 'id',label: 'name',children: 'childrenList'}"></select-tree>
        </el-form-item>
        <!-- 菜单类型【枚举】 -->
        <el-form-item :label="columnInfo.menuType.label" prop="menuType">
          <enum-dict-select v-model="ruleForm.menuType" module-name="system" dict-code="menuType"
                            @change="enumChange"></enum-dict-select>
        </el-form-item>
        <!-- 菜单名称 -->
        <el-form-item :label="columnInfo.name.label" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
<!--          <single-selector-input v-model="ruleForm.name"></single-selector-input>-->
        </el-form-item>
        <!-- 菜单图标 -->
        <el-form-item :label="columnInfo.icon.label" prop="icon" v-if="menuType==='M'||menuType==='C'">
          <el-input v-model="ruleForm.icon"></el-input>
        </el-form-item>
        <!-- 权限标识 -->
        <el-form-item :label="columnInfo.perms.label" prop="perms" v-if="menuType==='F'||menuType==='C'">
          <el-input v-model="ruleForm.perms"></el-input>
        </el-form-item>
        <!-- 显示顺序 -->
        <el-form-item :label="columnInfo.orderNum.label" prop="orderNum">
          <el-input-number v-model="ruleForm.orderNum" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <!-- 路由地址 -->
        <el-form-item :label="columnInfo.path.label" prop="path" v-if="menuType==='M'||menuType==='C'">
          <el-input v-model="ruleForm.path"></el-input>
        </el-form-item>
        <!-- 组件路径 -->
        <el-form-item :label="columnInfo.component.label" prop="component" v-if="menuType=== 'C'">
          <el-input v-model="ruleForm.component"></el-input>
        </el-form-item>
        <!-- 路由参数 -->
        <el-form-item :label="columnInfo.query.label" prop="query" v-if="menuType==='C'">
          <el-input v-model="ruleForm.query"></el-input>
        </el-form-item>
        <!-- 是否为外链【枚举】 -->
        <el-form-item :label="columnInfo.isFrame.label" prop="isFrame" v-if="menuType==='M'||menuType==='C'">
          <enum-dict-select v-model="ruleForm.isFrame" module-name="system" dict-code="binary"></enum-dict-select>
        </el-form-item>
        <!-- 是否缓存【枚举】 -->
        <el-form-item :label="columnInfo.isCache.label" prop="isCache" v-if="menuType=== 'C'">
          <enum-dict-select v-model="ruleForm.isCache" module-name="system" dict-code="binary"></enum-dict-select>
        </el-form-item>
        <!-- 是否显示【枚举】 -->
        <el-form-item :label="columnInfo.visible.label" prop="visible" v-if="menuType==='M'||menuType==='C'">
          <enum-dict-select v-model="ruleForm.visible" module-name="system" dict-code="binary"></enum-dict-select>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item :label="columnInfo.state.label" prop="state">
          <enum-dict-select v-model="ruleForm.state" module-name="system" dict-code="state"></enum-dict-select>
        </el-form-item>
      </template>
    </base-form-plus>
  </div>
</template>

<script>
/**
 *  菜单权限表表单
 *  @author PeterLee
 *  @since 2022-03-10
 */
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'MenuForm',
  data() {
    return {
      menuType: 'M',
    }
  },
  //计算操作
  computed: {
    ...mapState({
      //校验规则
      rules: state => state.system.menu.rules,
      //表格数据
      tableData: state => state.system.menu.tree.parentList,
      //表单信息
      formInfo: state => state.system.menu.formInfo,
      //下拉选项
      options: state => state.system.menu.options,
      //列信息
      columnInfo: state => state.system.menu.columnInfo,
    })
  },
  updated() {
    this.getParentTree();
  },
  methods: {
    ...mapMutations({
      setDialogVisible: 'system/menu/setDialogVisible',
      getParentTree: 'system/menu/getParentTree',
    }),
    ...mapActions({
      submitAdd: 'system/menu/submitAdd',
      submitEdit: 'system/menu/submitEdit',
    }),
    enumChange(data) {
      //M,C,F
      this.menuType = data;
    },
    /** 编辑菜单 */
    editMenu(data) {
      this.submitEdit(data).then(() =>{
        this.$emit("queryTable");
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>
