/**
 * 系统字典项表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2024-06-13
 */
const state = {
   //表单校验规则
   rules: {
      //字典id
      dictionaryId:[{
         required: true,
         message: '请选择字典',
         trigger: 'change'
       }],
      //字典项文本
      label:[{
         required: true,
         message: '请输入字典项文本',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //字典项值
      value:[{
         required: true,
         message: '请输入字典项值',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //回显类型
      style:[{
         required: false,
         message: '请选择回显类型',
         trigger: 'change'
       }],
      //备注
      comment:[{
         required: true,
         message: '请输入备注',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //排序号
      sort:[{
         required: true,
         message: '请输入排序号',
         trigger: 'blur'
       },
         {
           min: 1,
           max: 20,
           message: '长度在 1 到 20 个字符',
           trigger: 'blur'
         }
       ],
      //状态
      state:[{
         required: true,
         message: '请选择状态',
         trigger: 'change'
       }],
   },
   //列信息
   columnInfo: {
       //字典id
       dictionaryId:{
            visible:true,
            label:"字典名称"
       },
       //字典项文本
       label:{
            visible:true,
            label:"字典项文本"
       },
       //字典项值
       value:{
            visible:true,
            label:"字典项值"
       },
       //回显类型
       style:{
            visible:true,
            label:"回显类型"
       },
       //备注
       comment:{
            visible:true,
            label:"备注"
       },
       //排序号
       sort:{
            visible:true,
            label:"排序号"
       },
       //状态
       state:{
            visible:true,
            label:"状态"
       },
   },
   //表格数据（查询结果）
   tableData: [],
   //共有数据条数（分页控件）
   totalCount: 0,
   //选项列表（列表下拉框选择）
   options: {
      // 字典id (列表选项)
      dictionaryIdOptions: [],
   },
   //查询参数
   queryParams: {
      //当前页数（分页控件）
      currPage: 1,
      //每页条数（分页控件）
      limit: 10,
      //是否升序
      ascOrder: false,
      //排序字段
      orderField: "",
      //此处为可能需要修改部分，请不要忽略
      param: {
         //字典id
         dictionaryId: undefined,
         //字典项文本
         label: undefined,
         //字典项值
         value: undefined,
         //回显类型
         style: undefined,
         //备注
         comment: undefined,
         //排序号
         sort: undefined,
         //状态
         state: undefined,
      }
   },
   //时间范围
   dateRange: {
   },
   //表单组件信息
   formInfo: {
      //表单组件弹出框显示控制
      dialogVisible: false,
      //是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      //编辑回显数据
      editData: {},
   },
   //更多按钮信息
   moreButtonProps:[
      //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state