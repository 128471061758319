<template>
  <div>
    <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
    <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="数据源类型表"
                    :showData="formInfo.editData"
                    :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <!-- 类型名 -->
        <el-form-item :label="columnInfo.name.label" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <!-- 名称简写 -->
        <el-form-item :label="columnInfo.shortName.label" prop="shortName">
          <el-input v-model="ruleForm.shortName"></el-input>
        </el-form-item>
        <!-- 驱动名称 -->
        <el-form-item :label="columnInfo.driverName.label" prop="driverName">
          <el-input v-model="ruleForm.driverName"></el-input>
        </el-form-item>
        <!-- 路径前缀 -->
        <el-form-item :label="columnInfo.prefix.label" prop="prefix">
          <el-input v-model="ruleForm.prefix"></el-input>
        </el-form-item>
        <!-- 路径后缀 -->
        <el-form-item :label="columnInfo.suffix.label" prop="suffix">
          <el-input v-model="ruleForm.suffix" type="textarea"></el-input>
        </el-form-item>
        <!-- 排序 -->
        <el-form-item :label="columnInfo.sort.label" prop="sort">
          <el-input v-model="ruleForm.sort" type="number"></el-input>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item :label="columnInfo.state.label" prop="state">
          <enum-dict-select v-model="ruleForm.state" module-name="data" dict-code="state"></enum-dict-select>
        </el-form-item>
      </template>
    </base-form-plus>
  </div>
</template>

<script>
/**
 *  数据源类型表表单
 *  @author PeterLee
 *  @since 2022-07-18
 */
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'SourceTypeForm',
  //计算操作
  computed: {
    ...mapState({
      //校验规则
      rules: state => state.data.sourceType.rules,
      //表格数据
      tableData: state => state.data.sourceType.tableData,
      //表单信息
      formInfo: state => state.data.sourceType.formInfo,
      //下拉选项
      options: state => state.data.sourceType.options,
      //列信息
      columnInfo: state => state.data.sourceType.columnInfo,
    })
  },
  methods: {
    ...mapMutations({
      setDialogVisible: 'data/sourceType/setDialogVisible',
    }),
    ...mapActions({
      submitAdd: 'data/sourceType/submitAdd',
      submitEdit: 'data/sourceType/submitEdit',
    })
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>
