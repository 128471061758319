<template>
  <div style="display: inline;margin: 0 0 0 9px;">
    <el-button @click="clickMenuPermission" type="success" size="mini">
      <slot></slot>
    </el-button>
    <el-dialog v-dialogDrag :title="title" :visible.sync="dialogVisible" width="520px" @close="clickDialogClose" center>
      <!-- 信息描述区域 -->
      <el-descriptions :title="showTitle" :column="2" border>
        <el-descriptions-item label="角色名称">
          {{ data['name'] }}
        </el-descriptions-item>
        <el-descriptions-item label="角色编码">
          {{ data['code'] }}
        </el-descriptions-item>
      </el-descriptions>
      <div style="display: flex;flex-direction:column;">
        <div style="font-weight: bold;color:#303133;margin-top: 0.36rem;margin-bottom: 0.16rem;">
          <span>菜单权限</span>
        </div>
        <div style="width: 100%;">
          <el-checkbox v-model="expandAll" @change="handleCheckedTreeExpand($event)">展开/折叠</el-checkbox>
          <el-checkbox v-model="checkedAll" @change="handleCheckedTreeNodeAll($event)">全选/全不选</el-checkbox>
          <el-checkbox v-model="checkStrictly">父子联动</el-checkbox>
          <el-tree ref="tree" :data="treeData" show-checkbox :default-expand-all="expandAll" node-key="id" class="tree-border"
                   :check-strictly="!checkStrictly"
                   empty-text="加载中，请稍后"
                   :props="defaultProps">
          </el-tree>
        </div>
      </div>
      <!-- 底部按钮区 -->
      <template #footer class="dialog-footer">
			    <el-button type="primary" @click="clickConfirm">确 定</el-button>
			    <el-button @click="clickCancel">取 消</el-button>
		  </template>
    </el-dialog>
  </div>
</template>

<script>
import {getList} from "@/api/baseApi";
import {handleTree} from "@/utils/common";

export default {
  name:'PermissionSelect',
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'data',
  },
  //父组件传入参数
  props: {
    //角色对象数据
    data: Object,
  },
  //计算操作
  computed: {},
  data() {
    return {
      //组件内部弹出框展示与否
      dialogVisible: false,
      //弹出框标题
      title: '菜单权限',
      //展示标题
      showTitle: "角色信息",
      //树默认映射
      defaultProps: {
        children: 'childrenList',
        label: 'name'
      },
      //菜单数据
      treeData: [],
      //展开所有
      expandAll: false,
      //父子联动
      checkStrictly: true,
      //全选
      checkedAll: false,
    }
  },
  methods: {
    // 点击数据权限按钮
    clickMenuPermission(){
      this.dialogVisible = true;
      this.$nextTick(()=>{
        //请求关联的组织id,进行回显
        this.$systemApi.getMenuIdsByRoleId(this.data.id).then(res => {
          if (res.code === 200) {
            const checkedIds = res.data;
            checkedIds.forEach(item=>{
              const node = this.$refs.tree.getNode(item);
              this.$refs.tree.setChecked(node,true);
            })
          }
        }).catch(() => {})
      })
    },
    // 点击取消按钮
    clickCancel() {
      this.dialogVisible = false;
    },
    // 关闭弹窗时
    clickDialogClose() {

    },
    // 确认返回选中的组织信息
    clickConfirm() {
      this.updateDataScope();
    },
    // 更新数据操作范围
    updateDataScope() {
      let checkedKeys = [];
      //自定义数据权限时
      // 目前被选中的部门节点
      checkedKeys = this.$refs.tree.getCheckedKeys();
      // 半选中的部门节点
      let halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);

      const param = {
        roleId: this.data.id,
        menuIds: checkedKeys
      }
      //请求重置角色与组织关联
      this.$systemApi.roleBatchMenu(param).then(res => {
        if (res.code === 200) {
          this.$message.success("权限改变成功")
          this.dialogVisible = false;
        }
      })
    },
    // 查询权限树
    getMenuTree() {
      getList('system','menu').then(res => {
        if (res.code === 200) {
           this.treeData = handleTree(res.data,'id','parentId','childrenList');
        }
      })
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value) {
      let treeList = this.treeData;
      for (let i = 0; i < treeList.length; i++) {
        this.$refs.tree.store.nodesMap[treeList[i].id].expanded = value;
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value) {
      this.$refs.tree.setCheckedNodes(value ? this.treeData: []);
    },
  },
  created() {
    //创建时获取组织树
    this.getMenuTree()
  },
  mounted() {

  },
  //生命周期updated执行时
  updated() {

  }
}
</script>

<style lang="less" scoped>
/* tree border */
.tree-border {
  margin-top: 6px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius:4px;
  height: 330px;
  overflow: auto;
}
::v-deep .el-dialog__header {
  padding: 40px 20px 10px;
}
::v-deep .el-dialog__footer {
  padding: 10px 20px 26px;
}
@import '~@/styles/el-dialog-common.less';
</style>
