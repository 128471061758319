<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="系统字典项表"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm">
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 字典id -->
                        <el-form-item :label="columnInfo.dictionaryId.label" prop="dictionaryId">
                                <options-select v-model="ruleForm.dictionaryId" :options="options.dictionaryIdOptions" disabled></options-select>
                        </el-form-item>
                        <!-- 字典项文本 -->
                        <el-form-item :label="columnInfo.label.label" prop="label">
                                <el-input v-model="ruleForm.label" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 字典项值 -->
                        <el-form-item :label="columnInfo.value.label" prop="value">
                                <el-input v-model="ruleForm.value" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 回显类型 -->
                        <el-form-item :label="columnInfo.style.label" prop="style">
                                <enum-dict-select v-model="ruleForm.style" module-name="system" dict-code="labelStyle"></enum-dict-select>
                        </el-form-item>
                        <!-- 备注 -->
                        <el-form-item :label="columnInfo.comment.label" prop="comment">
                                <el-input v-model="ruleForm.comment" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 排序号 -->
                        <el-form-item :label="columnInfo.sort.label" prop="sort">
                                <el-input v-model="ruleForm.sort" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 状态 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                                <enum-dict-select v-model="ruleForm.state" module-name="system" dict-code="state"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
    /**
     * 	系统字典项表表单
     * 	@author PeterLee
     * 	@since 2024-06-13
     */
    import {mapState, mapMutations, mapActions} from "vuex";

    export default {
        name: 'dictionaryItemForm',
        // 计算操作
        computed: {
            ...mapState({
                // 校验规则
                rules: state => state.system.dictionaryItem.rules,
                // 表格数据
                tableData: state => state.system.dictionaryItem.tableData,
                // 表单信息
                formInfo: state => state.system.dictionaryItem.formInfo,
                // 下拉选项
                options: state => state.system.dictionaryItem.options,
                // 列信息
                columnInfo: state => state.system.dictionaryItem.columnInfo,
            })
        },
        methods: {
            ...mapMutations({
                setDialogVisible: 'system/dictionaryItem/setDialogVisible',
            }),
            ...mapActions({
                submitAdd: 'system/dictionaryItem/submitAdd',
                submitEdit: 'system/dictionaryItem/submitEdit',
            })
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
