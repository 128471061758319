<!-- 职位名展示标签（用于将职位id转为职位名） 参考：系统模块用户管理  作者：PeterLee 最近修改时间：2021.9.16-->
<template>
  <div>
    {{name}}
  </div>
</template>

<script>
export default {
  name: "PositionNameLabel",
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'id',
  },
  props: {
    id: String,
  },
  data() {
    return {
      //职位名显示
      name: ""
    };
  },
  watch: {
    id: function (newVal) {
      if (newVal) {
        this.getPositionName();
      }
    }
  },
  methods: {
    //获取职位名
    getPositionName() {
      this.$nextTick(() => {
        this.$store.dispatch('system/getPositionName', this.id).then(res => {
          this.name = res;
        })
      })
    }
  },
  mounted() {
    this.getPositionName();
  }
}
</script>

<style scoped>

</style>