<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="系统组织表	"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 组织名 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                                <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <!-- 组织代码 -->
                        <el-form-item :label="columnInfo.code.label" prop="code">
                                <el-input v-model="ruleForm.code"></el-input>
                        </el-form-item>
                        <!-- 父组织id -->
                        <el-form-item :label="columnInfo.parentId.label" prop="parentId">
                          <select-tree v-model="ruleForm.parentId" :options="[{id:'0',name:'根组织',childrenList:options.organizationOptions}]"
                                       :props="{value: 'id',label: 'name',children: 'childrenList'}"></select-tree>
                        </el-form-item>
<!--                        &lt;!&ndash; 祖级列表 &ndash;&gt;-->
<!--                        <el-form-item :label="columnInfo.ancestors.label" prop="ancestors">-->
<!--                                <el-input v-model="ruleForm.ancestors"></el-input>-->
<!--                        </el-form-item>-->
                        <!-- 层级 -->
                        <el-form-item :label="columnInfo.hierarchy.label" prop="hierarchy">
                                <el-input v-model="ruleForm.hierarchy"></el-input>
                        </el-form-item>
                        <!-- 排序号 -->
                        <el-form-item :label="columnInfo.sort.label" prop="sort">
                                <el-input v-model="ruleForm.sort"></el-input>
                        </el-form-item>
                        <!-- 状态 0：无效 1：有效 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                              <enum-dict-select v-model="ruleForm.state" dict-code="state" module-name="system"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
    /**
     * 	系统组织表	表单
     * 	@author PeterLee
     * 	@since 2022-06-23
     */
    import {mapState, mapMutations, mapActions} from "vuex";

    export default {
        name: 'OrganizationForm',
        //计算操作
        computed: {
            ...mapState({
                //校验规则
                rules: state => state.system.organization.rules,
                //表格数据
                tableData: state => state.system.organization.tableData,
                //表单信息
                formInfo: state => state.system.organization.formInfo,
                //下拉选项
                options: state => state.system.organization.options,
                //列信息
                columnInfo: state => state.system.organization.columnInfo,
            })
        },
        methods: {
            ...mapMutations({
                setDialogVisible: 'system/organization/setDialogVisible',
            }),
            ...mapActions({
                submitAdd: 'system/organization/submitAdd',
                submitEdit: 'system/organization/submitEdit',
            })
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
