<template>
  <div class="overview-container flex">
    <div style="margin: 10px;" class="flex1">
      <div style="font-size: 0.6rem;margin-bottom: 35px;font-weight: bolder;">
        Redid 新应用时代开拓者
      </div>
      <el-row :gutter="30"> <!-- 增加卡片间距 -->
        <el-col :span="6" v-for="item in overviewItems" :key="item.title">
          <div class="overview-card"> <!-- 添加背景色、阴影和圆角 -->
            <div slot="header" class="card-header"> <!-- 改进标题栏样式 -->
              <span>{{ item.title }}</span>
            </div>
            <div class="text item">
              {{ item.value }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="copyright">
      © 2024 Redid Project. All rights reserved.   |  备案号：蜀ICP备19030913号-1
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overviewItems: [
        { title: '用户', value: '1000' },
        { title: '数据源', value: '200' },
        { title: '项目', value: '50' },
        { title: '模板', value: '30' },
        { title: '定时任务', value: '30' },
        { title: '在线人数', value: '200' },
        { title: '日活人数', value: '500' },
        { title: '日请求次数', value: '10000' },
      ],
    };
  },
};
</script>

<style scoped>

.overview-container {
  padding: 30px;
  height: calc(100% - 1.3rem);
  background-image: url('~@/assets/overview_background.webp'); /* 背景图 */
  background-size: cover;
  color: #FFFFFF;
  background-position: center;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #FFFFFF;
}

.overview-card {
  margin-bottom: 30px;
  background: linear-gradient(to bottom right, #232429, #892D20); /* 添加渐变背景 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
  color: #F8E7C7; /* 设置文本颜色以确保在渐变背景上可读 */
}

.overview-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
}

.card-header {
  padding: 20px 15px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* 修改边框颜色以适应渐变背景 */
}

.card-header span {
  padding-top: 12px;
  padding-left: 12px;
  font-weight: bolder;
  font-size: 0.4rem;
}

.text.item {
  font-size: 0.7rem;
  font-weight: bolder;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 56px;
}

.copyright {
  position: absolute;
  bottom: 32px;
  left: 0;
  width: 70%;
  height: 22px;
  text-align: right;
  margin-right: 100px;
  color: #FFFFFF; /* 确保颜色与背景图对比明显 */
  font-size: 14px;
}
</style>