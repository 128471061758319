<template>
  <div>
      系统设置
  </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>

<style lang="less" scoped>

</style>