import axios from '@/config/httpConfig'

const modelName = "data"

/**
 * 测试连接数据源
 * @param id
 * @returns {Promise<unknown>}
 */
export function connect(id) {
    return axios.get("/" + modelName + "/source/connect/" + id);
}

/**
 * 拉取数据库信息
 * @param id 数据源id
 * @returns {Promise<unknown>}
 */
export function pullDataBase(id) {
    return axios.get("/" + modelName + "/dataBase/pull/" + id);
}

/**
 * 拉取数据库表
 * @param id 数据库id
 */
export function pullDataTable(id) {
    return axios.get("/" + modelName + "/table/pull/" + id);
}

/**
 * 拉取数据字段表
 * @param id 数据表id
 */
export function pullDataField(id) {
    return axios.get("/" + modelName + "/field/pull/" + id);
}

/**
 * 执行sql语句（勾选表）
 * @param data 勾选表数据
 */
export function handleEachTable(data) {
    return axios.post("/" + modelName + "/dataBase/executeOfTable", data);
}

/**
 * 代码预览
 *
 * @param id
 * @returns {Promise | Promise<unknown>}
 */
export function codePreview(id) {
    return axios.get("/" + modelName + "/table/code/show/" + id);
}

/**
 * 创建菜单，模板生成代码后，添加路由菜单
 * @param id
 * @returns {Promise | Promise<unknown>}
 */
export function createMenu(id) {
    return axios.get("/" + modelName + "/table/menu/" + id)
}
