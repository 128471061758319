<template>
  <div style="height: 100%;">
    <el-row :gutter="20" class="container">
      <!--右边主要区-->
      <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
        <!--右边头部搜索区-->
        <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch"
                 label-width="110px">
          <!-- 类型名 -->
          <el-form-item :label="columnInfo.name.label" prop="name">
            <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 名称简写 -->
          <el-form-item :label="columnInfo.shortName.label" prop="shortName">
            <el-input v-model="queryParams.param.shortName" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 驱动名称 -->
          <el-form-item :label="columnInfo.driverName.label" prop="name">
            <el-input v-model="queryParams.param.driverName" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 路径前缀 -->
          <el-form-item :label="columnInfo.prefix.label" prop="prefix">
            <el-input v-model="queryParams.param.prefix" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 路径后缀 -->
          <el-form-item :label="columnInfo.suffix.label" prop="suffix">
            <el-input v-model="queryParams.param.suffix" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 排序 -->
          <el-form-item :label="columnInfo.sort.label" prop="sort">
            <el-input v-model="queryParams.param.sort" placeholder="请输入内容" size="small" clearable></el-input>
          </el-form-item>
          <!-- 状态 -->
          <el-form-item :label="columnInfo.state.label" prop="state">
            <enum-dict-select v-model="queryParams.param.state" module-name="data" dict-code="state"  placeholder="请选择" size="small" clearable/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="small" @click="handleTableSearch">搜索</el-button>
            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
          </el-form-item>
        </el-form>
        <!--右边中部功能区-->
        <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="small"
                @click="clickAdd"
                v-permissionDisabled="'data:sourceType:create'"
            >新增
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <excel-import-plus title="数据源类型表" module-name="data" table-name="sourceType"
                               :permission-code="'data:sourceType:import'"></excel-import-plus>
          </el-col>
          <el-col :span="1.5">
            <excel-export-plus file-name="数据源类型表" module-name="data" table-name="sourceType" :select-list="selectList"
                               :permission-code="'data:sourceType:export'"></excel-export-plus>
          </el-col>
          <el-col :span="1.5">
            <el-button
                plain
                size="small"
                :loading="exportLoading"
                @click="handleBack"
            >返回<i class="el-icon-refresh-left el-icon--right"></i></el-button>
          </el-col>
          <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                              :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
        </el-row>
        <!--右边中部表格区-->
        <div class="main">
          <div class="table">
            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                      @selection-change="handleSelectionChange" height="100%"
                      :header-cell-style="{background:'#ECF5FF'}">
              <el-table-column align="center" type="selection" width="55"/>
              <!-- 类型名 -->
              <af-table-column prop="name" :label="columnInfo.name.label"
                               v-if="columnInfo.name.visible"></af-table-column>
              <!-- 名称简写 -->
              <af-table-column prop="shortName" :label="columnInfo.shortName.label"
                               v-if="columnInfo.shortName.visible"></af-table-column>
              <!-- 驱动名称 -->
              <af-table-column prop="driverName" :label="columnInfo.driverName.label"
                               v-if="columnInfo.driverName.visible"></af-table-column>
              <!-- 路径前缀 -->
              <af-table-column prop="prefix" :label="columnInfo.prefix.label"
                               v-if="columnInfo.prefix.visible"></af-table-column>
              <!-- 路径后缀 -->
              <el-table-column align="center" prop="suffix" :label="columnInfo.suffix.label"
                               v-if="columnInfo.suffix.visible" show-overflow-tooltip></el-table-column>
              <!-- 排序 -->
              <af-table-column prop="sort" :label="columnInfo.sort.label"
                               v-if="columnInfo.sort.visible"></af-table-column>
              <!-- 状态 -->
              <el-table-column align="center" prop="state" :label="columnInfo.state.label"
                               v-if="columnInfo.state.visible">
                <template v-slot="scope">
                  <enum-dict-label v-model="scope.row.state" dict-code="state" module-name="data"
                                   show-tag></enum-dict-label>
                </template>
              </el-table-column>
              <!-- 操作列 -->
              <af-table-column label="操作" width="255" fixed="right">
                <template v-slot="scope">
                  <el-button @click="clickEdit(scope.row)" type="primary" size="mini"
                             v-permissionDisabled="'data:sourceType:update'">编辑
                  </el-button>
                  <el-button @click="clickDel(scope.row)" type="danger" size="mini"
                             v-permissionDisabled="'data:sourceType:remove'">删除
                  </el-button>
                  <table-more-button :row="scope.row" :button-props="moreButtonProps"
                                     @handleCommand="handleCommand"></table-more-button>
                </template>
              </af-table-column>
            </el-table>
          </div>
        </div>
        <!--右边底部分页区-->
        <div style="height: 40px;margin-bottom: 0.1rem;">
          <pagination-plus :totalCount="totalCount"
                           :param="queryParams"
                           :pageSizes="pageSizes"
                           :selectList="selectList"
                           module-name="data"
                           table-name="sourceType"
                           :del-all-permission-code="'data:sourceType:removes'"
                           :state-permission-code="'data:sourceType:state'"
                           @handleTableRefresh="handleTableSearch"/>
        </div>
      </el-col>
    </el-row>
    <!-- 弹出层组件 -->
    <SourceTypeForm/>
  </div>
</template>

<script>
/**
 * 数据源类型表主页面 （与自定义组件之间请遵循MVVM架构）
 * @author PeterLee
 * @since 2022-07-18
 */
import SourceTypeForm from "./SourceTypeForm"
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  components: {
    //表单弹出组件
    SourceTypeForm
  },
  data() {
    return {
      //每页条数选项（分页控件）
      pageSizes: [10, 20, 40, 80],
      //批量操作选中list（分页控件）
      selectList: [],
      //展示搜索区（right-toolbar）
      showSearch: false,
      //导出loading
      exportLoading: false,
    }
  },
  computed: {
    ...mapState({
      //表格数据
      tableData: state => state.data.sourceType.tableData,
      //下拉选项
      options: state => state.data.sourceType.options,
      //共有数据条数（分页控件）
      totalCount: state => state.data.sourceType.totalCount,
      //列信息
      columnInfo: state => state.data.sourceType.columnInfo,
      //查询参数
      queryParams: state => state.data.sourceType.queryParams,
      //时间范围
      dateRange: state => state.data.sourceType.dateRange,
      //更多按钮信息
      moreButtonProps: state => state.data.sourceType.moreButtonProps,
    })
  },
  //生命周期create函数
  created() {
    //创建时获取所有权限数据（分页查询）
    this.handleTableSearch();
    //获取OptionList
    this.getOptionList();
  },
  methods: {
    ...mapMutations({
      clickEdit: 'data/sourceType/clickEdit',
      clickAdd: 'data/sourceType/clickAdd',
    }),
    ...mapActions({
      clickDel: 'data/sourceType/handleDel',
      handleTableSearch: 'data/sourceType/handleTableSearch',
      getOptionList: 'data/sourceType/getOptionList',
    }),
    /** 重置按钮点击（搜索区） */
    clickReset() {
      for (let key of Object.keys(this.dateRange)) {
        this.dateRange[key] = []
      }
      this.$refs['queryForm'].resetFields();
      this.handleTableSearch();
    },
    /** 处理选择改变（表格区） */
    handleSelectionChange(selectList) {
      this.selectList = selectList;
    },
    /** 重新计算表格（按钮区） */
    redoTableLayout() {
      //数据加载完成后执行
      this.$nextTick(() => {
        //表格重新渲染
        this.$refs.multipleTable.doLayout();
      });
    },
    /** 更多按钮方法处理（按钮区） */
    handleCommand(command, row) {
      // console.log(command)
      // console.log(row)
    },
    /** 处理返回 */
    handleBack() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/style/index.less');

::v-deep .el-form-item {
  margin-bottom: 18px;
}
</style>
