<template>
  <div style="display: inline;margin: 0 8px;">
    <el-button @click="clickCodeConfirmButton" size="mini">
      <slot></slot>
    </el-button>
    <el-dialog :visible.sync="dialogVisible" width="500px" @close="clickDialogClose" center
               append-to-body top="188px">
      <!-- 生成菜单选择区 -->
      <div style="max-height: 8rem;overflow-y: auto;margin-top: 0.25rem;margin-right: 1rem;">
         <div style="font-size: 20px;color: black;margin-left: 1rem;">请选择需要生成的菜单信息：</div>
         <div style="font-size: 12px;color: gray;margin-left: 1rem;margin-bottom: 0.4rem;">温馨提示：生成代码前请确认菜单权限码是否和代码中的匹配</div>
         <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-form-item label="模块名" prop="moduleName">
              <el-input v-model="form.moduleName"></el-input>
            </el-form-item>
           <el-form-item label="模块码" prop="moduleCode">
             <el-input v-model="form.moduleCode"></el-input>
           </el-form-item>
           <el-form-item label="菜单名" prop="tableName">
             <el-input v-model="form.tableName"></el-input>
           </el-form-item>
           <el-form-item label="菜单码" prop="tableCode">
             <el-input v-model="form.tableCode"></el-input>
           </el-form-item>
           <el-form-item style="margin-top: 16px;margin-left: 1.4rem;display: flex;justify-content: right;">
             <el-button type="primary" @click="clickConfirm('form')">确 定</el-button>
             <el-button @click="clickCancel">取 消</el-button>
           </el-form-item>
         </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {createMenu} from "@/api/dataApi";
import {createMenuTemplate} from "@/api/systemApi";
/**
 * 菜单创建按钮
 *
 * @author PeterLee
 * @date 2022-09-22
 */
export default {
  name: 'CodeConfirmButton',
  // 父组件传入参数
  props: {
    tableId:String,
  },
  // 计算操作
  computed: {},
  data() {
    return {
      // 组件内部弹出框展示与否
      dialogVisible: false,
      checkedIds:[],
      form: {
        moduleName: '',
        moduleCode: '',
        tableName: '',
        tableCode: ''
      },
      rules: {
          moduleName:[{
            required: true,
            message: '请输入模块名',
            trigger: 'blur'
          },{
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
          }],
          moduleCode:[{
            required: true,
            message: '请输入模块码',
            trigger: 'blur'
          },{
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
          }],
          tableName:[{
            required: true,
            message: '请输入菜单名',
            trigger: 'blur'
          },{
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
          }],
          tableCode:[{
            required: true,
            message: '请输入菜单码',
            trigger: 'blur'
          },{
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
          }]
      }
    }
  },
  methods: {
    // 点击数据权限按钮
    clickCodeConfirmButton() {
      createMenu(this.tableId).then(res => {
        if (res.code === 200) {
          this.form = res.data;
          this.dialogVisible = true;
        }
      })
    },
    // 点击取消按钮
    clickCancel() {
      this.dialogVisible = false;
    },
    // 关闭弹窗时
    clickDialogClose() {
      this.dialogVisible = false;
    },
    //提交表单
    clickConfirm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            createMenuTemplate(this.form).then(res =>{
              if (res.code === 200) {
                this.$message.success(res.message)
                this.dialogVisible = false;
              }
            })
          } else {
            //校验未通过
            this.$message.error("数据校验未通过，请核对！")
            return false;
          }
        });
    },
  },
  created() {

  },
  // mounted生命周期
  mounted() {

  },
  // 生命周期updated执行时
  updated() {

  }
}
</script>

<style lang="less" scoped>
/* tree border */
.tree-border {
  margin-top: 5px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius: 4px;
}
</style>
