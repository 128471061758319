<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
                <!--右边主要区-->
                <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                    <!--右边头部搜索区-->
                    <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="110px">
                                <!-- 父菜单ID -->
<!--                                <el-form-item :label="columnInfo.parentId.label" prop="parentId">-->
<!--                                    <select-tree v-model="queryParams.param.parentId" :options="[{id:'0',name:'主目录',childrenList:tree.tableData}]"-->
<!--                                                  :props="{value: 'id',label: 'name',children: 'childrenList'}"></select-tree>-->
<!--                                </el-form-item>-->
                                <!-- 菜单类型【枚举】 -->
<!--                                <el-form-item :label="columnInfo.menuType.label" prop="menuType">-->
<!--                                        <enum-dict-select v-model="queryParams.param.menuType" dict-code="menuType" module-name="system" size="small"></enum-dict-select>-->
<!--                                </el-form-item>-->
                                <!-- 菜单名称 -->
                                <el-form-item :label="columnInfo.name.label" prop="name">
                                        <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 菜单图标 -->
<!--                                <el-form-item :label="columnInfo.icon.label" prop="icon">-->
<!--                                        <el-input v-model="queryParams.param.icon" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 权限标识 -->
<!--                                <el-form-item :label="columnInfo.perms.label" prop="perms">-->
<!--                                        <el-input v-model="queryParams.param.perms" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 显示顺序 -->
<!--                                <el-form-item :label="columnInfo.orderNum.label" prop="orderNum">-->
<!--                                        <el-input v-model="queryParams.param.orderNum" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 路由地址 -->
<!--                                <el-form-item :label="columnInfo.path.label" prop="path">-->
<!--                                        <el-input v-model="queryParams.param.path" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 组件路径 -->
<!--                                <el-form-item :label="columnInfo.component.label" prop="component">-->
<!--                                        <el-input v-model="queryParams.param.component" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 路由参数 -->
<!--                                <el-form-item :label="columnInfo.query.label" prop="query">-->
<!--                                        <el-input v-model="queryParams.param.query" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 是否为外链【枚举】 -->
<!--                                <el-form-item :label="columnInfo.isFrame.label" prop="isFrame">-->
<!--                                      <enum-dict-select v-model="queryParams.param.isFrame" dict-code="binary" module-name="system" size="small"></enum-dict-select>-->
<!--                                </el-form-item>-->
                                <!-- 是否缓存【枚举】 -->
<!--                                <el-form-item :label="columnInfo.isCache.label" prop="isCache">-->
<!--                                      <enum-dict-select v-model="queryParams.param.isCache" dict-code="binary" module-name="system" size="small"></enum-dict-select>-->
<!--                                </el-form-item>-->
                                <!-- 是否显示【枚举】 -->
                                <el-form-item :label="columnInfo.visible.label" prop="visible">
                                      <enum-dict-select v-model="queryParams.param.visible" dict-code="binary" module-name="system" size="small"></enum-dict-select>
                                </el-form-item>
                                <!-- 状态 -->
                                <el-form-item :label="columnInfo.state.label" prop="state">
                                      <enum-dict-select v-model="queryParams.param.state" dict-code="state" module-name="system" size="small"></enum-dict-select>
                                </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="handleTableSearch">搜索</el-button>
                            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <!-- 右边中部功能区 -->
                    <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                        <el-col :span="1.5">
                            <el-button
                                    type="primary"
                                    plain
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="clickAdd"
                                    v-permissionDisabled="'system:menu:create'"
                            >新增</el-button>
                        </el-col>
<!--                        <el-col :span="1.5">-->
<!--                            <excel-import-plus title="菜单权限表" module-name="system" table-name="menu" :permission-code="'system:menu:import'"></excel-import-plus>-->
<!--                        </el-col>-->
<!--                        <el-col :span="1.5">-->
<!--                            <excel-export-plus file-name="菜单权限表" module-name="system" table-name="menu" :select-list="selectList" :permission-code="'system:menu:export'"></excel-export-plus>-->
<!--                        </el-col>-->
                        <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="queryTable"
                                            :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
                    </el-row>
                    <!-- 右边中部表格区 -->
                    <div class="main">
                        <div class="table">
                            <el-table border row-key="id" :tree-props="{children: 'childrenList',hasChildren: 'hasChildren'}" lazy  :load="load" ref="multipleTable" :data="tree.tableData" tooltip-effect="dark" style="width: 100%;"
                                      @selection-change="handleSelectionChange" height="100%" :header-cell-style="{background:'#ECF5FF'}">
                                <el-table-column align="center" type="selection" width="55"/>
                                        <!-- 父菜单ID -->
                                        <af-table-column align="center" prop="parentId" :label="columnInfo.parentId.label" v-if="columnInfo.parentId.visible"></af-table-column>
                                        <!-- 菜单名称 -->
                                        <el-table-column align="left" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible" width="175"></el-table-column>
                                        <!-- 菜单类型【枚举】 -->
                                        <el-table-column align="center" prop="menuType" :label="columnInfo.menuType.label" v-if="columnInfo.menuType.visible">
                                            <template v-slot="scope">
                                                  <enum-dict-label v-model="scope.row.menuType" module-name="system" dict-code="menuType"
                                                                    show-tag></enum-dict-label>
                                            </template>
                                        </el-table-column>
                                        <!-- 菜单图标 -->
                                        <af-table-column align="center"  prop="icon" :label="columnInfo.icon.label" v-if="columnInfo.icon.visible"></af-table-column>
                                        <!-- 显示顺序 -->
                                        <af-table-column align="center" prop="orderNum" :label="columnInfo.orderNum.label" v-if="columnInfo.orderNum.visible"></af-table-column>
                                        <!-- 权限标识 -->
                                        <el-table-column align="center" prop="perms" :label="columnInfo.perms.label" v-if="columnInfo.perms.visible" width="200"></el-table-column>
                                        <!-- 路由地址 -->
                                        <el-table-column align="center" prop="path" :label="columnInfo.path.label" v-if="columnInfo.path.visible" width="125"></el-table-column>
                                        <!-- 组件路径 -->
                                        <el-table-column align="center" prop="component" :label="columnInfo.component.label" v-if="columnInfo.component.visible" width="300"></el-table-column>
                                        <!-- 路由参数 -->
                                        <af-table-column align="center" prop="query" :label="columnInfo.query.label" v-if="columnInfo.query.visible"></af-table-column>
                                        <!-- 是否为外链【枚举】 -->
                                        <af-table-column align="center" prop="isFrame" :label="columnInfo.isFrame.label" v-if="columnInfo.isFrame.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.isFrame" module-name="system" dict-code="binary"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 是否缓存【枚举】 -->
                                        <af-table-column align="center" prop="isCache" :label="columnInfo.isCache.label" v-if="columnInfo.isCache.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.isCache" module-name="system" dict-code="binary"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 是否显示【枚举】 -->
                                        <af-table-column align="center" prop="visible" :label="columnInfo.visible.label" v-if="columnInfo.visible.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.visible" module-name="system" dict-code="binary"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 状态 -->
                                        <af-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.state" module-name="system" dict-code="state"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                <!-- 操作列 -->
                                <af-table-column  align="center" label="操作" width="255" fixed="right">
                                    <template v-slot="scope">
                                        <el-button @click="clickEdit(scope.row)" type="primary" size="mini" v-permissionDisabled="'system:menu:update'">编辑</el-button>
                                        <el-button @click="clickDel({row:scope.row,callback:refreshLoadTree})" type="danger" size="mini" v-permissionDisabled="'system:menu:remove'">删除</el-button>
                                        <permission-scan-button :row="scope.row" :disabled="scope.row.menuType!=='M'" @clickClose="queryTable">权限扫描</permission-scan-button>
<!--                                        <table-more-button :row="scope.row" :button-props="moreButtonProps" @handleCommand="handleCommand"></table-more-button>-->
<!--                                        <batch-selector-button>关联用户</batch-selector-button>-->
                                    </template>
                                </af-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!--右边底部分页区-->
                    <div style="height: 4px;margin-bottom: 0.1rem;">
<!--                        <pagination-plus :totalCount="totalCount"-->
<!--                                         :param="queryParams"-->
<!--                                         :pageSizes="pageSizes"-->
<!--                                         :selectList="selectList"-->
<!--                                         module-name="system"-->
<!--                                         table-name="menu"-->
<!--                                         :del-all-permission-code="'system:menu:removes'"-->
<!--                                         :state-permission-code="'system:menu:state'"-->
<!--                                         @handleTableRefresh="handleTableSearch"/>-->
                    </div>
                </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <MenuForm @queryTable="queryTable"/>
    </div>
</template>

<script>
    /**
     * 菜单权限表主页面 （与自定义组件之间请遵循MVVM架构）
     * @author PeterLee
     * @since 2022-03-10
     */
    import MenuForm from "./MenuForm"
    import {mapState, mapMutations, mapActions} from "vuex";
    import PermissionScanButton from "@/views/system/components/PermissionScanButton";

    export default {
        components: {
            //表单弹出组件
            MenuForm,
            // 权限扫描按钮
            PermissionScanButton
        },
        data() {
            return {
                //每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                //批量操作选中list（分页控件）
                selectList: [],
                //展示搜索区（right-toolbar）
                showSearch: false,
                //导出loading
                exportLoading: false,
                //懒加载资源map
                lazyMap: new Map(),
            }
        },
        computed: {
            ...mapState({
                //树
                tree: state => state.system.menu.tree,
                //下拉选项
                options: state => state.system.menu.options,
                //共有数据条数（分页控件）
                totalCount: state => state.system.menu.totalCount,
                //列信息
                columnInfo: state => state.system.menu.columnInfo,
                //查询参数
                queryParams: state => state.system.menu.queryParams,
                //时间范围
                dateRange: state => state.system.menu.dateRange,
                //更多按钮信息
                moreButtonProps: state => state.system.menu.moreButtonProps,
            })
        },
        //生命周期create函数
        created() {
            //创建时获取所有权限数据（分页查询）
            this.handleTableSearch();
            //获取OptionList
            this.getOptionList();
        },
        methods: {
            ...mapMutations({
                clickEdit: 'system/menu/clickEdit',
                clickAdd: 'system/menu/clickAdd',
            }),
            ...mapActions({
                lazyLoad: 'system/menu/lazyLoad',
                clickDel: 'system/menu/handleDel',
                handleTableSearch: 'system/menu/handleTableSearch',
                getOptionList: 'system/menu/getOptionList',
            }),
            /** 重置按钮点击（搜索区） */
            clickReset() {
                for (let key of Object.keys(this.dateRange)) {
                    this.dateRange[key] = []
                }
                this.$refs['queryForm'].resetFields();
                this.handleTableSearch();
            },
            /** 处理选择改变（表格区） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /** 查询表格（按钮区） */
            queryTable() {
              this.handleTableSearch().then(() => {
                //此处为重新加载展开项,时序应该在handleTableSearch之后
                this.redoTableLayout();
              })
            },
            /** 重新计算表格（按钮区） */
            redoTableLayout() {
                //数据加载完成后执行
                this.$nextTick(() => {
                    //表格重新渲染
                    this.$refs.multipleTable.doLayout();
                    // this.$router.go(0); //该处为直接刷新当前页面
                    this.lazyMap.forEach(({ tree, treeNode, resolve },key) => {
                      // 此处为重新加载展开项，但时序应该在handleTableSearch之后(等待大版本规则优化)
                      this.refreshLoadTree(key)
                    });
                });
            },
            /** 更多按钮方法处理（按钮区） */
            handleCommand(command,row) {
                // console.log(command)
                // console.log(row)
            },
            /** 表格懒加载 */
            load(tree, treeNode, resolve) {
              let treeId = tree['id']
              // 把map清空
              // this.lazyMap = new Map();
              this.lazyMap.set( treeId, { tree, treeNode, resolve })
              this.lazyLoad({"treeId":treeId,"resolve":resolve});
            },
            /** 刷新懒加载树 */
            refreshLoadTree(treeId) {
              if (treeId === undefined || treeId === '0') {
                 return
              }
              // 根据更改节点的父ID取出相应数据
              const { tree, treeNode, resolve } = this.lazyMap.get(treeId)
              this.$set(
                  // 不要忘记在你的table上写ref
                  this.$refs.multipleTable.store.states.lazyTreeNodeMap,
                  treeId,
                  []
              );
              if (tree) {
                // 将取出对应数据再传给load方法
                this.load(tree, treeNode, resolve);
              }
            },
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');

    ::v-deep .el-form-item {
        margin-bottom: 18px;
    }
</style>
