<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="数据库表信息"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 名称 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                          <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                        <!-- 数据库id -->
<!--                        <el-form-item :label="columnInfo.dataBaseId.label" prop="dataBaseId">-->
<!--                                <el-input v-model="ruleForm.dataBaseId"></el-input>-->
<!--                        </el-form-item>-->
                        <!-- 表注释 -->
                        <el-form-item :label="columnInfo.annotation.label" prop="annotation">
                                <el-input v-model="ruleForm.annotation" disabled></el-input>
                        </el-form-item>
                        <!-- 备注 -->
                        <el-form-item :label="columnInfo.comment.label" prop="comment">
                          <el-input v-model="ruleForm.comment" disabled></el-input>
                        </el-form-item>
                        <!-- 状态 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                          <enum-dict-select v-model="ruleForm.state" module-name="data" dict-code="state" disabled></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
/**
 *  数据库表信息表单
 *  @author PeterLee
 *  @since 2022-07-18
 */
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'TableForm',
  // 计算操作
  computed: {
    ...mapState({
      // 校验规则
      rules: state => state.data.table.rules,
      // 表格数据
      tableData: state => state.data.table.tableData,
      // 表单信息
      formInfo: state => state.data.table.formInfo,
      // 下拉选项
      options: state => state.data.table.options,
      // 列信息
      columnInfo: state => state.data.table.columnInfo,
    })
  },
  methods: {
    ...mapMutations({
      setDialogVisible: 'data/table/setDialogVisible',
    }),
    ...mapActions({
      submitAdd: 'data/table/submitAdd',
      submitEdit: 'data/table/submitEdit',
    })
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>
