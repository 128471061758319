/**
 * 系统职务表状态管理（vuex的state可以单独出来，便于管理，同样mutations和actions都可以提取出来）
 *
 * @author PeterLee
 * @since 2022-06-23
 */
const state = {
   //表单校验规则
   rules: {
      //职务名称
      name:[{
         required: true,
         message: '请输入职务名称',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
     ],
      //职位编码
      code:[{
         required: true,
         message: '请输入职位编码',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
        }
     ],
      //职务描述
      description:[{
         required: true,
         message: '请输入职务描述',
         trigger: 'blur'
      },
        {
            min: 1,
            max: 120,
            message: '长度在 1 到 120 个字符',
            trigger: 'blur'
        }
     ],
      //状态 0：停用 1：启用
      state:[{
         required: true,
         message: '请选择状态',
         trigger: 'change'
      }],
   },
   //列信息
   columnInfo: {
       //职务名称
       name:{
            visible:true,
            label:"职务名称"
       },
       //职位编码
       code:{
            visible:true,
            label:"职位编码"
       },
       //职务描述
       description:{
            visible:true,
            label:"职务描述"
       },
       //状态 0：停用 1：启用
       state:{
            visible:true,
            label:"状态"
       },
   },
   //表格数据（查询结果）
   tableData: [],
   //共有数据条数（分页控件）
   totalCount: 0,
   //选项列表（列表下拉框选择）
   options: {},
   //查询参数
   queryParams: {
      //当前页数（分页控件）
      currPage: 1,
      //每页条数（分页控件）
      limit: 10,
      //是否升序
      ascOrder: false,
      //排序字段
      orderField: "",
      //此处为可能需要修改部分，请不要忽略
      param: {
         //职务名称
         name: undefined,
         //职位编码
         code: undefined,
         //职务描述
         description: undefined,
         //状态 0：停用 1：启用
         state: undefined,
      }
   },
   //时间范围
   dateRange: {
   },
   //表单组件信息
   formInfo: {
      //表单组件弹出框显示控制
      dialogVisible: false,
      //是否增加操作（非增加操作会隐藏部分操作）
      isAdd: false,
      //编辑回显数据
      editData: {},
   },
   //更多按钮信息
   moreButtonProps:[
      //{command:'test',label:'示例按钮',permissionCode:'itil:event'}
   ]
}
export default state