<template>
  <div>
    <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
    <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="数据源表"
                    :showData="formInfo.editData"
                    :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <!-- 名称 -->
        <el-form-item :label="columnInfo.name.label" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <!-- 资源路径 -->
        <el-form-item :label="columnInfo.url.label" prop="url">
          <el-input v-model="ruleForm.url"></el-input>
        </el-form-item>
        <!-- 账号 -->
        <el-form-item :label="columnInfo.account.label" prop="account">
          <el-input v-model="ruleForm.account"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item :label="columnInfo.password.label" prop="password">
          <el-input v-model="ruleForm.password"></el-input>
        </el-form-item>
        <!-- 类型 -->
        <el-form-item :label="columnInfo.typeId.label" prop="typeId">
          <options-select v-model="ruleForm.typeId" :options="options.typeOptions" placeholder="请选择"
                          style="width: 100%;" clearable></options-select>
        </el-form-item>
        <!-- 序号 -->
        <el-form-item :label="columnInfo.sort.label" prop="sort">
          <el-input v-model="ruleForm.sort"></el-input>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item :label="columnInfo.state.label" prop="state">
          <enum-dict-select v-model="ruleForm.state" dict-code="state" module-name="data"></enum-dict-select>
        </el-form-item>
      </template>
    </base-form-plus>
  </div>
</template>

<script>
/**
 *  数据源表表单
 *  @author PeterLee
 *  @since 2022-07-15
 */
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'SourceForm',
  // 计算操作
  computed: {
    ...mapState({
      // 校验规则
      rules: state => state.data.source.rules,
      // 表格数据
      tableData: state => state.data.source.tableData,
      // 表单信息
      formInfo: state => state.data.source.formInfo,
      // 下拉选项
      options: state => state.data.source.options,
      // 列信息
      columnInfo: state => state.data.source.columnInfo,
    })
  },
  methods: {
    ...mapMutations({
      setDialogVisible: 'data/source/setDialogVisible',
    }),
    ...mapActions({
      submitAdd: 'data/source/submitAdd',
      submitEdit: 'data/source/submitEdit',
    })
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>
