/**
 * 本文件存放在form表单和index页面中使用的常量数据，作为相关页面的"数据提供者"
 * 参考链接（Alt/Ctrl+单击点开）：https://element.eleme.cn/#/zh-CN/component/form
 * 文件服务器组表数据提供者 作者：PeterLee 时间：2021-11-22
 */
//表单数据校验规则
const rules = {
    name:[{
         required: true,
         message: '请输入名字',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    code:[{
         required: true,
         message: '请输入编码',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    address:[{
         required: true,
         message: '请输入地址',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 100,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    domain:[{
         required: true,
         message: '请输入访问域名',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    state:[{
         required: true,
         message: '请输入状态',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
}
//列信息
const columnInfo={
    name:{
        visible:true,
        label:"名字"
    },
    code:{
        visible:true,
        label:"编码"
    },
    address:{
        visible:true,
        label:"地址"
    },
    domain:{
        visible:true,
        label:"访问域名"
    },
    state:{
        visible:true,
        label:"状态"
    },
}

//下拉框选项(没有下拉框选项可以删除，此处只为范例)
const options={

}
//时间范围
const dateRange= {
}
//查询参数
const queryParams={
    //当前页数（分页控件）
    currPage: 1,
    //每页条数（分页控件）
    limit: 10,
    //是否升序
    ascOrder: false,
    //排序字段
    orderField: "",
    //此处为可能需要修改部分，请不要忽略
    param: {
        name: undefined,
        code: undefined,
        address: undefined,
        domain: undefined,
        state: undefined,
    }
}
export {
    rules,
    options,
    queryParams,
    columnInfo,
    dateRange
}
