<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="系统字典表"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 字典名称 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                                <el-input v-model="ruleForm.name" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 字典编码 -->
                        <el-form-item :label="columnInfo.code.label" prop="code">
                                <el-input v-model="ruleForm.code" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 备注 -->
                        <el-form-item :label="columnInfo.comment.label" prop="comment">
                                <el-input v-model="ruleForm.comment" placeholder="请输入内容"  clearable></el-input>
                        </el-form-item>
                        <!-- 状态 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                                <enum-dict-select v-model="ruleForm.state" module-name="system" dict-code="state"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
    /**
     * 	系统字典表表单
     * 	@author PeterLee
     * 	@since 2024-06-13
     */
    import {mapState, mapMutations, mapActions} from "vuex";

    export default {
        name: 'dictionaryForm',
        // 计算操作
        computed: {
            ...mapState({
                // 校验规则
                rules: state => state.system.dictionary.rules,
                // 表格数据
                tableData: state => state.system.dictionary.tableData,
                // 表单信息
                formInfo: state => state.system.dictionary.formInfo,
                // 下拉选项
                options: state => state.system.dictionary.options,
                // 列信息
                columnInfo: state => state.system.dictionary.columnInfo,
            })
        },
        methods: {
            ...mapMutations({
                setDialogVisible: 'system/dictionary/setDialogVisible',
            }),
            ...mapActions({
                submitAdd: 'system/dictionary/submitAdd',
                submitEdit: 'system/dictionary/submitEdit',
            })
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
