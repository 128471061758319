<template>
  <div>
    <base-form :isAdd="true" :relationDialogVisible.sync="dialogVisible" showTitle="修改密码"
               :rules="rules" @submitAdd="changePassword" @closeDialog="closeDialog" width="24%"
               ref="baseForm" defaultEmpty>
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input type="password" v-model="ruleForm.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="ruleForm.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="againPassword">
          <el-input type="password" v-model="ruleForm.againPassword"></el-input>
        </el-form-item>
      </template>
    </base-form>
  </div>
</template>

<script>
import {changePassword} from "@/api/systemApi";
import BaseForm from "@/components/widget/BaseForm";
export default {
  name: "PasswordChangeForm",
  components: {
    BaseForm
  },
  props: {
    visible:Boolean
  },
  watch: {
    visible: {
      handler:function ( newVal,oldVal ) {
        if (newVal === true) {
          this.dialogVisible = true
        }
        if (newVal === false) {
          this.dialogVisible = false
        }
      }
    }
  },
  data() {
    return {
      dialogVisible:false,
      rules:{
        oldPassword:[{
          required: true,
          message: '请输入原密码',
          trigger: 'blur'
        },
          {
            min: 1,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
        newPassword:[{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        },
          {
            min: 1,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
        againPassword:[{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        },
          {
            min: 1,
            max: 30,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  methods: {
    //修改密码
    changePassword(data) {
      if(data.oldPassword===data.newPassword){
        this.$message.error("新旧密码相同，请重新输入")
        return;
      }
      if(data.againPassword!==data.newPassword){
        this.$message.error("两次输入的密码不相等")
        return;
      }
      changePassword(data).then(res=>{
        if(res.code===200){
          this.$message.success("密码修改成功")
          this.$refs.baseForm.setVisible(false);
          this.$emit("success", false);
        }
      })
    },
    closeDialog() {
      this.dialogVisible=false;
      this.$emit("update:visible", false);
      this.$emit("success", false);
    }
  }
}
</script>

<style scoped>

</style>