<template>
  <div style="height: 100%;">
    <el-row :gutter="20" class="container">
      <!-- 右边主要区 -->
      <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
        <!-- 右边头部搜索区 -->
        <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch"
                 label-width="110px">
          <!-- 名称 -->
          <el-form-item :label="columnInfo.name.label" prop="name">
            <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
          </el-form-item>
          <!-- 数据库id -->
          <!--                                <el-form-item :label="columnInfo.dataBaseId.label" prop="dataBaseId">-->
          <!--                                        <el-input v-model="queryParams.param.dataBaseId" placeholder="请输入内容" size="small" clearable></el-input>-->
          <!--                                </el-form-item>-->
          <!-- 表注释 -->
          <el-form-item :label="columnInfo.annotation.label" prop="annotation">
            <el-input v-model="queryParams.param.annotation" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
          </el-form-item>
          <!-- 备注 -->
          <el-form-item :label="columnInfo.comment.label" prop="comment">
            <el-input v-model="queryParams.param.comment" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
          </el-form-item>
          <!-- 状态 -->
          <el-form-item :label="columnInfo.state.label" prop="state">
            <enum-dict-select v-model="queryParams.param.state" module-name="data" dict-code="state" placeholder="请选择"
                              size="small" clearable/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="small" @click="clickSearch">搜索</el-button>
            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 右边中部功能区 -->
        <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="small"
                @click="clickAdd"
                disabled
                v-permissionDisabled="'data:table:create'"
            >新增
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <excel-import-plus title="数据库表信息" module-name="data" table-name="table"
                               :permission-code="'data:table:import'"></excel-import-plus>
          </el-col>
          <el-col :span="1.5">
            <excel-export-plus file-name="数据库表信息" module-name="data" table-name="table" :select-list="selectList"
                               :permission-code="'data:table:export'"></excel-export-plus>
          </el-col>
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-download"
                size="small"
                @click="handlePull"
            >拉取
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-coordinate"
                size="small"
                @click="handleSqlExecute"
            >sql执行
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                plain
                size="small"
                :loading="exportLoading"
                @click="handleBack"
            >返回<i class="el-icon-refresh-left el-icon--right"></i></el-button>
          </el-col>
          <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                              :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
        </el-row>
        <!-- 右边中部表格区 -->
        <div class="main">
          <div class="table">
            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                      @selection-change="handleSelectionChange" height="100%"
                      :header-cell-style="{background:'#ECF5FF'}">
              <el-table-column align="center" type="selection" width="55"/>
              <!-- 名称 -->
              <el-table-column align="center" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible">
                <template v-slot="scope">
                  <el-link type="primary" @click="linkDataField(scope.row)">{{ scope.row.name }}</el-link>
                </template>
              </el-table-column>
              <!-- 数据库id -->
              <!--                                        <af-table-column align="center" prop="dataBaseId" :label="columnInfo.dataBaseId.label" v-if="columnInfo.dataBaseId.visible"></af-table-column>-->
              <!-- 表注释 -->
              <el-table-column align="center" prop="annotation" :label="columnInfo.annotation.label"
                               v-if="columnInfo.annotation.visible"></el-table-column>
              <!-- 备注 -->
              <el-table-column align="center" prop="comment" :label="columnInfo.comment.label"
                               v-if="columnInfo.comment.visible"></el-table-column>
              <!-- 状态 -->
              <el-table-column align="center" prop="state" :label="columnInfo.state.label"
                               v-if="columnInfo.state.visible">
                <template slot-scope="scope">
                  <enum-dict-label v-model="scope.row.state" dict-code="state" module-name="data"
                                   show-tag></enum-dict-label>
                </template>
              </el-table-column>
              <!-- 操作列 -->
              <el-table-column align="center" label="操作" width="255" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                  <code-preview-button :table-id="scope.row.id">预览</code-preview-button>
<!--                  <el-button @click="clickEdit(scope.row)" type="primary" size="mini"-->
<!--                             v-permissionDisabled="'data:table:update'">编辑-->
<!--                  </el-button>-->
                  <el-button @click="clickDel(scope.row)" disabled type="danger" size="mini"
                             v-permissionDisabled="'data:table:remove'">删除
                  </el-button>
                  <table-menu-create-button :table-id="scope.row.id">添加菜单</table-menu-create-button>
                  <!--                                      <table-more-button :row="scope.row" :button-props="moreButtonProps" @handleCommand="handleCommand">更多</table-more-button>-->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 右边底部分页区 -->
        <div style="height: 40px;margin-bottom: 0.1rem;">
          <pagination-plus :totalCount="totalCount"
                           :param="queryParams"
                           :pageSizes="pageSizes"
                           :selectList="selectList"
                           module-name="data"
                           table-name="table"
                           del-disabled
                           :del-all-permission-code="'data:table:removes'"
                           :state-permission-code="'data:table:state'"
                           @handleTableRefresh="handleTableSearch"/>
        </div>
      </el-col>
    </el-row>
    <!-- 弹出层组件 -->
    <TableForm/>
    <SqlExecuteForm :relation-dialog-visible.sync="sqlExecuteVisible" :show-data="sqlExeData"/>
  </div>
</template>

<script>
/**
 * 数据库表信息主页面 （与自定义组件之间请遵循MVVM架构）
 * @author PeterLee
 * @since 2022-07-18
 */
import TableForm from "./TableForm"
import {mapActions, mapMutations, mapState} from "vuex";
import { pullDataTable } from "@/api/dataApi";
import SqlExecuteForm from "@/views/data/table/components/SqlExecuteForm";
import CodePreviewButton from "@/views/data/table/components/CodePreviewButton";
import TableMenuCreateButton from "@/views/data/table/components/TableMenuCreateButton";

export default {
  components: {
    // 表单弹出组件
    TableForm,
    SqlExecuteForm,
    CodePreviewButton,
    TableMenuCreateButton
  },
  data() {
    return {
      // 每页条数选项（分页控件）
      pageSizes: [10, 20, 40, 80],
      // 批量操作选中list（分页控件）
      selectList: [],
      // 展示搜索区（right-toolbar）
      showSearch: false,
      // 导出loading
      exportLoading: false,
      // sql执行数据（勾选列ids）
      sqlExeData: {
        baseId: "",
        sql: "",
        tableIds: []
      },
      // 编辑数据（弹出框表单）
      editData: {},
      // 是否添加操作（弹出框表单）
      isAdd: false,
      // 配置弹出框显示控制
      configDialogVisible: false,
      // sql执行弹出框显示控制
      sqlExecuteVisible: false,
    }
  },
  computed: {
    ...mapState({
      // 表格数据
      tableData: state => state.data.table.tableData,
      // 下拉选项
      options: state => state.data.table.options,
      // 共有数据条数（分页控件）
      totalCount: state => state.data.table.totalCount,
      // 列信息
      columnInfo: state => state.data.table.columnInfo,
      // 查询参数
      queryParams: state => state.data.table.queryParams,
      // 时间范围
      dateRange: state => state.data.table.dateRange,
      // 更多按钮信息
      moreButtonProps: state => state.data.table.moreButtonProps,
    })
  },
  // 生命周期create函数
  created() {
    // 数据库id
    this.queryParams.param.dataBaseId = this.$route.params.id;
    // 创建时获取所有权限数据（分页查询）
    this.handleTableSearch();
    // 获取OptionList
    this.getOptionList();
  },
  methods: {
    ...mapMutations({
      clickEdit: 'data/table/clickEdit',
      clickAdd: 'data/table/clickAdd',
    }),
    ...mapActions({
      clickDel: 'data/table/handleDel',
      handleTableSearch: 'data/table/handleTableSearch',
      getOptionList: 'data/table/getOptionList',
    }),
    /** 重置按钮点击（搜索区） */
    clickReset() {
      for (let key of Object.keys(this.dateRange)) {
        this.dateRange[key] = []
      }
      this.$refs['queryForm'].resetFields();
      this.handleTableSearch();
    },
    /** 搜索按钮点击（搜索区） */
    clickSearch() {
      this.queryParams.currPage = 1;
      this.handleTableSearch();
    },
    /** 处理选择改变（表格区） */
    handleSelectionChange(selectList) {
      this.selectList = selectList;
    },
    /** 重新计算表格（按钮区） */
    redoTableLayout() {
      // 数据加载完成后执行
      this.$nextTick(() => {
        // 表格重新渲染
        this.$refs.multipleTable.doLayout();
      });
    },
    /** 更多按钮方法处理（按钮区） */
    handleCommand(command, row) {
      // console.log(command)
      // console.log(row)
    },
    // 跳转到数据字段页面
    linkDataField(row) {
      this.$router.push({name: 'DataField/:id', params: {id: row.id}})
    },
    /** 处理拉取 */
    handlePull() {
      // 网络请求
      pullDataTable(this.$route.params.id).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '信息拉取成功！'
          });
          // 刷新数据
          this.handleTableSearch();
        }
      })
    },
    /** 处理sql执行 **/
    handleSqlExecute() {
      if (this.selectList === null || this.selectList.length === 0) {
        this.$message({
          type: 'info',
          message: '请选择要操作的数据'
        });
        return;
      }
      this.sqlExeData.tableIds = this.selectList.map(item => {
        return item.id
      });
      this.sqlExecuteVisible = true;
    },
    /** 处理返回 */
    handleBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/style/index.less');

::v-deep .el-form-item {
  margin-bottom: 18px;
}
</style>
