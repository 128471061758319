<template>
  <div class="operationArgument">
      <div class="page-top">
        <span>
          <span>管理设置&emsp;</span>
           <el-input
            placeholder="输入内容进行模糊搜索"
            prefix-icon="el-icon-search"
            v-model="settingName">
          </el-input>
        </span>
      </div>
     <div class="content">
       <div class="settingItem" v-for="(item,index) in settings" :key="index">
         <div class="itemTitle">
           {{item.name}}
         </div>
         <div class="child">
           <span @click="clickTo(it)" v-for="(it,i) in item.children" :key="i">{{it.settingName}}</span>
         </div>
       </div>
     </div>
     <div>
       <router-view></router-view>
     </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      settingName:"",
      settings:[
        {
          name:"资产管理设置",
          icon:"",
          children:[
            {
              settingName:"资产分类",
              to:"/system/operationArgument/assetClassify",
            },
            {
              settingName:"厂家信息",
            },
            {
              settingName:"资产状态",
            },
            {
              settingName:"维修状态",
            },
            {
              settingName:"附加字段",
            },
          ]
        },
        {
          name:"对象管理设置",
          icon:"",
          children:[
            {
              settingName:"应用类型",
            },
            {
              settingName:"操作类型",
            },
            {
              settingName:"日志类型",
            },
            {
              settingName:"对象类型",
            },
            {
              settingName:"告警方式",
            },
            {
              settingName:"监测指标",
            },
          ]
        },
        {
          name:"人工巡检设置",
          icon:"",
          children:[
            {
              settingName:"人工巡检对象类型",
            },
            {
              settingName:"人工巡检对象指标",
            },
            {
              settingName:"人工维护对象类型",
            },
            {
              settingName:"人工维护对象指标",
            },
          ]
        },
        {
          name:"制度管理设置",
          icon:"",
          children:[
            {
              settingName:"预案类型",
            },
            {
              settingName:"制度类型",
            },
            {
              settingName:"制度检查脚本",
            },
          ]
        },
        {
          name:"用户配置",
          icon:"",
          children:[
            {
              settingName:"处理人",
            },
            {
              settingName:"审批人",
              to:"/system/operationArgument/approver"
            },
            {
              settingName:"核实人",
            },
          ]
        },
      ]
    }
  },
  methods:{
    clickTo(item){
      if(item.to){
        this.$router.push(item.to)
      }
    },
  },
  created(){
  }
}
</script>

<style lang="less" scoped>
@import url("../../../assets/style/common.less");
@import url("../style/operationArgument.less");
</style>