<script>
export default {
  data() {
    return {
      loading: false,
      registerForm: {
        username: '',
        password: '',
        confirmPassword: '',
        email: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'));
              } else if (value !== this.registerForm.password) {
                callback(new Error('两次输入密码不一致!'));
              } else {
                callback();
              }
            }, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      }
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          alert('注册成功!');
        } else {
          console.log('验证失败!');
          return false;
        }
      });
    }
  }
};
</script>

<template>
  <div class="register-container">
    <vue-particles
        :color="'#AEDFFE'"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="4"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        style="height: 100%;"
    />
    <div style="position: absolute;top:4rem;left: 6rem;">
      <div class="flex flex-ac">
        <div style="font-size: 42px;font-weight: bolder;color: #fff;">Redid</div>
        <div style="font-size: 32px;font-weight: bolder;color: #fff;margin-left: 0.2rem;">开放平台</div>
      </div>
      <div style="font-size: 24px;font-weight: bolder;color: #fff;">
        __助力数字技术革新,为产业赋能_
      </div>
    </div>
    <el-form :model="registerForm" :rules="rules" ref="registerForm" label-position="left" label-width="0px" class="register-page">
      <div class="title">注册redid开放平台</div>
      <el-form-item  prop="email">
        <el-input placeholder="邮箱" v-model="registerForm.email" prefix-icon="el-icon-message"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input placeholder="用户名" v-model="registerForm.username" prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input placeholder=""></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" id="registerButton" style="width:100%;" :loading="loading" @click="handleSubmit">立即注册</el-button>
      </el-form-item>
      <router-link class="flex-ajc register" to="/userLogin">
        已有redid账户，前往登录>>
      </router-link>
    </el-form>
  </div>
</template>

<style scoped lang="less">
.register-page {
  position: absolute;
  top: 28%; /* 调整垂直位置 */
  left: 60%; /* 调整水平位置 */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 286px; /* 根据需要调整宽度 */
  padding: 35px 45px 20px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.register-container {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/overview_background.webp'); /* 背景图 */
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.title {
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  opacity: 0.88;
}
.register {
  color: #3a8ee6;
  margin-bottom: 20px;
  font-weight:bold;
  opacity: 0.6;
}
</style>