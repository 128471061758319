<template>
  <div style="display: inline;margin: 0 0 0 9px;">
    <el-button @click="clickDataScope" type="primary" size="mini">
      <slot></slot>
    </el-button>
    <el-dialog v-dialogDrag :title="title" :visible.sync="dialogVisible" width="520px" @close="clickDialogClose" center>
      <!-- 信息描述区域 -->
      <el-descriptions :title="showTitle" :column="2" border>
        <el-descriptions-item label="角色名称">
          {{ data['name'] }}
        </el-descriptions-item>
        <el-descriptions-item label="角色编码">
          {{ data['code']}}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 数据范围下拉框 -->
      <div style="display: flex;flex-direction:column;width: 100%;">
			  <div style="font-weight: bold;color:#303133;margin-top: 0.26rem;margin-bottom: 0.26rem;">
          <span>数据范围</span>
        </div>
        <el-select v-model="data.dataScope" placeholder="请选择" size="middle" style="flex: 1;">
          <el-option v-for="item in dataScopeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div style="display: flex;flex-direction:column;" v-if="data.dataScope===1">
        <div style="font-weight: bold;color:#303133;margin-top: 0.26rem;margin-bottom: 0.26rem;">
          <span>数据权限</span>
        </div>
        <div style="width: 100%;">
          <el-checkbox v-model="expandAll" @change="handleCheckedTreeExpand($event)">展开/折叠</el-checkbox>
          <el-checkbox v-model="checkedAll" @change="handleCheckedTreeNodeAll($event)">全选/全不选</el-checkbox>
          <el-checkbox v-model="checkStrictly">父子联动</el-checkbox>
          <el-tree :data="treeData" show-checkbox default-expand-all node-key="id" ref="tree" class="tree-border" style="overflow: auto;height: 5rem;"
                   :check-strictly="!checkStrictly"
                   empty-text="加载中，请稍后"
                   :props="defaultProps">
          </el-tree>
        </div>
      </div>
      <!-- 底部按钮区 -->
      <template #footer class="dialog-footer">
			    <el-button type="primary" @click="clickConfirm">确 定</el-button>
			    <el-button @click="clickCancel">取 消</el-button>
		  </template>
    </el-dialog>
  </div>
</template>

<script>
import {updateDataScope} from "@/api/systemApi";

export default {
  name: 'DataScopeSelect',
  //设置v-model绑定对象和自动更新响应方法
  model: {
    prop: 'data',
  },
  //父组件传入参数
  props: {
    //角色对象数据
    data: Object,
  },
  //计算操作
  computed: {},
  data() {
    return {
      //组件内部弹出框展示与否
      dialogVisible: false,
      //弹出框标题
      title: '授权数据',
      //数据范围  0：全部 1：自定义 2：本组织 3：本组织及以下 4:仅本人
      dataScopeOptions: [{
        value: 0,
        label: '全部数据权限'
      }, {
        value: 1,
        label: '自定义数据权限'
      }, {
        value: 2,
        label: '本组织数据权限'
      }, {
        value: 3,
        label: '本组织及以下数据权限'
      }, {
        value: 4,
        label: '仅本人数据权限'
      }],
      //展示标题
      showTitle: "角色信息",
      //oldScope,进入时数据范围
      oldScope: 0,
      //树默认映射
      defaultProps: {
        children: 'childrenList',
        label: 'name'
      },
      //组织树数据
      treeData: [],
      //展开所有
      expandAll: true,
      //父子联动
      checkStrictly: true,
      //全选
      checkedAll: false,
    }
  },
  methods: {
    //点击数据权限按钮
    clickDataScope(){
      this.dialogVisible = true;
      //自定义数据权限时
      if(this.data.dataScope===1){
        this.$nextTick(()=>{
          //请求关联的组织id,进行回显
          this.$systemApi.getOrganizationIdsByRoleId(this.data.id).then(res => {
            if (res.code === 200) {
              const checkedIds = res.data;
              checkedIds.forEach(item=>{
                const node = this.$refs.tree.getNode(item);
                this.$refs.tree.setChecked(node,true);
              })
            }
          })
        })
      }
    },
    //点击取消按钮
    clickCancel() {
      this.data.dataScope = this.oldScope;
      this.dialogVisible = false;
    },
    //关闭弹窗时
    clickDialogClose() {
      this.data.dataScope = this.oldScope;
    },
    //确认返回选中的组织信息
    clickConfirm() {
      this.updateDataScope();
    },
    //更新数据操作范围
    updateDataScope() {
      let checkedKeys = [];
      //自定义数据权限时
      if(this.data.dataScope === 1){
        // 目前被选中的部门节点
        checkedKeys = this.$refs.tree.getCheckedKeys();
        // 半选中的部门节点
        let halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();
        checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      }
      const data ={
         roleId: this.data.id,
         dataScope: this.data.dataScope,
         organizationIds: checkedKeys
      }
      //是编辑操作
      updateDataScope(data).then(res=>{
        if (res.code === 200) {
          //关闭弹出层并刷新树
          this.oldScope = data.dataScope;
          this.$message.success("更新数据操作范围成功");
          this.dialogVisible = false;
        }
      })
    },
    //查询组织树
    getOrganizationTree() {
      this.$systemApi.getOrganizationTree().then(res => {
        if (res.code === 200) {
          this.treeData = res.data
        }
      })
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value) {
      let treeList = this.treeData;
      for (let i = 0; i < treeList.length; i++) {
        this.$refs.tree.store.nodesMap[treeList[i].id].expanded = value;
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value) {
      this.$refs.tree.setCheckedNodes(value ? this.treeData: []);
    },
  },
  created() {
    //创建时获取组织树
    this.getOrganizationTree()  
    this.oldScope=this.data.dataScope;
  },
  mounted() {

  },
  //生命周期updated执行时
  updated() {

  }
}
</script>

<style lang="less" scoped>
/* tree border */
.tree-border {
  margin-top: 6px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius:4px;
}
::v-deep .el-dialog__header {
  padding: 36px 20px 0;
}
::v-deep .el-dialog__footer {
  padding: 10px 20px 26px;
}
@import '~@/styles/el-dialog-common.less';
</style>
