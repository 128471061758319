<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="数据库表"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 数据库名 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                        <!-- 数据源id -->
<!--                        <el-form-item :label="columnInfo.dataSourceId.label" prop="dataSourceId">-->
<!--                                <el-input v-model="ruleForm.dataSourceId"></el-input>-->
<!--                        </el-form-item>-->
                        <!-- 备注 -->
                        <el-form-item :label="columnInfo.comment.label" prop="comment">
                            <el-input v-model="ruleForm.comment" disabled></el-input>
                        </el-form-item>
                        <!-- 模块名 -->
                        <el-form-item  prop="moduleName">
                            <label-tip :label="columnInfo.moduleName.label" :tip="columnInfo.moduleName.tip" slot="label"/>
                            <el-input v-model="ruleForm.moduleName"></el-input>
                        </el-form-item>
                        <!-- 作者名字 -->
                        <el-form-item :label="columnInfo.authorName.label" prop="authorName">
                            <label-tip :label="columnInfo.authorName.label" :tip="columnInfo.authorName.tip" slot="label"/>
                            <el-input v-model="ruleForm.authorName"></el-input>
                        </el-form-item>
                        <!-- 模块名 -->
                        <el-form-item prop="moduleDescription">
                            <label-tip :label="columnInfo.moduleDescription.label" :tip="columnInfo.moduleDescription.tip" slot="label"/>
                            <el-input v-model="ruleForm.moduleDescription"></el-input>
                        </el-form-item>
                        <!-- 包名 -->
                        <el-form-item prop="packageName">
                            <label-tip :label="columnInfo.packageName.label" :tip="columnInfo.packageName.tip" slot="label"/>
                            <el-input v-model="ruleForm.packageName"></el-input>
                        </el-form-item>
                        <!-- 表前缀（为空即不去掉前缀） -->
                        <el-form-item :label="columnInfo.prefix.label" prop="prefix" :tip="columnInfo.prefix.tip">
                            <label-tip :label="columnInfo.prefix.label" :tip="columnInfo.prefix.tip" slot="label"/>
                            <el-input v-model="ruleForm.prefix"></el-input>
                        </el-form-item>
                        <!-- 后端路径 -->
                        <el-form-item :label="columnInfo.javaPath.label" prop="javaPath">
                            <label-tip :label="columnInfo.javaPath.label" :tip="columnInfo.javaPath.tip" slot="label"/>
                            <directory-select v-model="ruleForm.javaPath"></directory-select>
                        </el-form-item>
                        <!-- 前端路径 -->
                        <el-form-item :label="columnInfo.vuePath.label" prop="vuePath">
                            <label-tip :label="columnInfo.vuePath.label" :tip="columnInfo.vuePath.tip" slot="label"/>
                            <directory-select v-model="ruleForm.vuePath"></directory-select>
                        </el-form-item>
                        <!-- 状态 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                            <enum-dict-select v-model="ruleForm.state" module-name="data" dict-code="state"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
/**
 *  数据库表表单
 *  @author PeterLee
 *  @since 2022-07-18
 */
import {mapActions, mapMutations, mapState} from "vuex";
import ElFormTipItem from "@/components/widget/LabelTip";

export default {
  name: 'DataBaseForm',
  components: {
    ElFormTipItem
  },
  //计算操作
  computed: {
    ...mapState({
      //校验规则
      rules: state => state.data.dataBase.rules,
      //表格数据
      tableData: state => state.data.dataBase.tableData,
      //表单信息
      formInfo: state => state.data.dataBase.formInfo,
      //下拉选项
      options: state => state.data.dataBase.options,
      //列信息
      columnInfo: state => state.data.dataBase.columnInfo,
    })
  },
  methods: {
    ...mapMutations({
      setDialogVisible: 'data/dataBase/setDialogVisible',
    }),
    ...mapActions({
      submitAdd: 'data/dataBase/submitAdd',
      submitEdit: 'data/dataBase/submitEdit',
    })
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>
