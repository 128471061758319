<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
                <!--右边主要区-->
                <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                    <!--右边头部搜索区-->
                    <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="110px">
                                <!-- 数据库名 -->
                                <el-form-item :label="columnInfo.name.label" prop="name">
                                        <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 数据源id -->
<!--                                <el-form-item :label="columnInfo.dataSourceId.label" prop="dataSourceId">-->
<!--                                        <el-input v-model="queryParams.param.dataSourceId" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 备注 -->
                                <el-form-item :label="columnInfo.comment.label" prop="comment">
                                        <el-input v-model="queryParams.param.comment" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 模块名 -->
                                <el-form-item :label="columnInfo.moduleName.label" prop="moduleName">
                                        <el-input v-model="queryParams.param.moduleName" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 作者名字 -->
                                <el-form-item :label="columnInfo.authorName.label" prop="authorName">
                                        <el-input v-model="queryParams.param.authorName" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 模块描述 -->
                                <el-form-item :label="columnInfo.moduleDescription.label" prop="moduleDescription">
                                        <el-input v-model="queryParams.param.moduleDescription" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 包名 -->
                                <el-form-item :label="columnInfo.packageName.label" prop="packageName">
                                        <el-input v-model="queryParams.param.packageName" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 表前缀（为空即不去掉前缀） -->
                                <el-form-item :label="columnInfo.prefix.label" prop="prefix">
                                        <el-input v-model="queryParams.param.prefix" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 后端路径 -->
                                <el-form-item :label="columnInfo.javaPath.label" prop="javaPath">
                                        <el-input v-model="queryParams.param.javaPath" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 前端路径 -->
                                <el-form-item :label="columnInfo.vuePath.label" prop="vuePath">
                                        <el-input v-model="queryParams.param.vuePath" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 状态 -->
                                <el-form-item :label="columnInfo.state.label" prop="state">
                                        <enum-dict-select v-model="queryParams.param.state" module-name="data" dict-code="state"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="handleTableSearch">搜索</el-button>
                            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <!--右边中部功能区-->
                    <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                        <el-col :span="1.5">
                            <el-button
                                    type="primary"
                                    plain
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="clickAdd"
                                    disabled
                                    v-permissionDisabled="'data:dataBase:create'"
                            >新增</el-button>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-import-plus title="数据库表" module-name="data" table-name="dataBase" :permission-code="'data:dataBase:import'"></excel-import-plus>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-export-plus file-name="数据库表" module-name="data" table-name="dataBase" :select-list="selectList" :permission-code="'data:dataBase:export'"></excel-export-plus>
                        </el-col>
                        <el-col :span="1.5">
                          <el-button
                              type="primary"
                              plain
                              icon="el-icon-download"
                              size="small"
                              @click="handlePull"
                          >拉取
                          </el-button>
                        </el-col>
                        <el-col :span="1.5">
                          <el-button
                              plain
                              size="small"
                              :loading="exportLoading"
                              @click="handleBack"
                          >返回<i class="el-icon-refresh-left el-icon--right"></i></el-button>
                        </el-col>
                        <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                                            :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
                    </el-row>
                    <!--右边中部表格区-->
                    <div class="main">
                        <div class="table">
                            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                      @selection-change="handleSelectionChange" height="100%" :header-cell-style="{background:'#ECF5FF'}">
                                <el-table-column align="center" type="selection" width="55"/>
                                        <!-- 数据库名 -->
                                        <af-table-column align="center" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible">
                                          <template v-slot="scope">
                                            <el-link type="primary" @click="linkDataTable(scope.row)">{{ scope.row.name }}</el-link>
                                          </template>
                                        </af-table-column>
                                        <!-- 数据源id -->
                                        <af-table-column align="center" prop="dataSourceId" :label="columnInfo.dataSourceId.label" v-if="columnInfo.dataSourceId.visible">
                                          <template slot-scope="scope">
                                            <option-name-label v-model="scope.row.dataSourceId"
                                                               :options="options.sourceOptions"></option-name-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 备注 -->
                                        <el-table-column align="center" prop="comment" :label="columnInfo.comment.label" v-if="columnInfo.comment.visible"></el-table-column>
                                        <!-- 模块code -->
                                        <af-table-column align="center" prop="moduleName" :label="columnInfo.moduleName.label" v-if="columnInfo.moduleName.visible"></af-table-column>
                                        <!-- 作者名字 -->
                                        <af-table-column align="center" prop="authorName" :label="columnInfo.authorName.label" v-if="columnInfo.authorName.visible"></af-table-column>
                                        <!-- 模块名 -->
                                        <af-table-column align="center" prop="moduleDescription" :label="columnInfo.moduleDescription.label" v-if="columnInfo.moduleDescription.visible"></af-table-column>
                                        <!-- 包名 -->
                                        <af-table-column align="center" prop="packageName" :label="columnInfo.packageName.label" v-if="columnInfo.packageName.visible" width="85"></af-table-column>
                                        <!-- 表前缀（为空即不去掉前缀） -->
                                        <el-table-column align="center" prop="prefix" :label="columnInfo.prefix.label" v-if="columnInfo.prefix.visible"></el-table-column>
                                        <!-- 后端路径 -->
                                        <af-table-column align="center" prop="javaPath" :label="columnInfo.javaPath.label" v-if="columnInfo.javaPath.visible" show-overflow-tooltip></af-table-column>
                                        <!-- 前端路径 -->
                                        <af-table-column align="center" prop="vuePath" :label="columnInfo.vuePath.label" v-if="columnInfo.vuePath.visible" show-overflow-tooltip></af-table-column>
                                        <!-- 状态 -->
                                        <el-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible">
                                          <template slot-scope="scope">
                                            <enum-dict-label v-model="scope.row.state" dict-code="state" module-name="data"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </el-table-column>
                                <!-- 操作列 -->
                                <el-table-column align="center" label="操作" width="255" fixed="right">
                                    <template slot-scope="scope">
                                        <el-button @click="clickEdit(scope.row)" type="primary" size="mini" v-permissionDisabled="'data:dataBase:update'">配置</el-button>
                                        <el-button @click="clickDel(scope.row)" disabled type="danger" size="mini" v-permissionDisabled="'data:dataBase:remove'">删除</el-button>
                                        <table-more-button :row="scope.row" :button-props="moreButtonProps" @handleCommand="handleCommand"></table-more-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!--右边底部分页区-->
                    <div style="height: 40px;margin-bottom: 0.1rem;">
                        <pagination-plus :totalCount="totalCount"
                                         :param="queryParams"
                                         :pageSizes="pageSizes"
                                         :selectList="selectList"
                                         module-name="data"
                                         table-name="dataBase"
                                         del-disabled
                                         :del-all-permission-code="'data:dataBase:removes'"
                                         :state-permission-code="'data:dataBase:state'"
                                         @handleTableRefresh="handleTableSearch"/>
                    </div>
                </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <DataBaseForm/>
    </div>
</template>

<script>
/**
 * 数据库表主页面 （与自定义组件之间请遵循MVVM架构）
 * @author PeterLee
 * @since 2022-07-18
 */
import DataBaseForm from "./DataBaseForm";
import {mapActions, mapMutations, mapState} from "vuex";
import {pullDataBase} from "@/api/dataApi";

    export default {
        components: {
            // 表单弹出组件
            DataBaseForm
        },
        data() {
            return {
                // 每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                // 批量操作选中list（分页控件）
                selectList: [],
                // 展示搜索区（right-toolbar）
                showSearch: false,
                // 导出loading
                exportLoading: false,
            }
        },
        computed: {
            ...mapState({
                // 表格数据
                tableData: state => state.data.dataBase.tableData,
                // 下拉选项
                options: state => state.data.dataBase.options,
                // 共有数据条数（分页控件）
                totalCount: state => state.data.dataBase.totalCount,
                // 列信息
                columnInfo: state => state.data.dataBase.columnInfo,
                // 查询参数
                queryParams: state => state.data.dataBase.queryParams,
                // 时间范围
                dateRange: state => state.data.dataBase.dateRange,
                // 更多按钮信息
                moreButtonProps: state => state.data.dataBase.moreButtonProps,
            })
        },
        // 生命周期create函数
        created() {
            // 数据源id
            this.queryParams.param.dataSourceId = this.$route.params.id;
            // 创建时获取所有权限数据（分页查询）
            this.handleTableSearch();
            // 获取OptionList
            this.getOptionList();
        },
        methods: {
            ...mapMutations({
                clickEdit: 'data/dataBase/clickEdit',
                clickAdd: 'data/dataBase/clickAdd',
            }),
            ...mapActions({
                clickDel: 'data/dataBase/handleDel',
                handleTableSearch: 'data/dataBase/handleTableSearch',
                getOptionList: 'data/dataBase/getOptionList',
            }),
            /** 重置按钮点击（搜索区） */
            clickReset() {
                for (let key of Object.keys(this.dateRange)) {
                    this.dateRange[key] = []
                }
                this.$refs['queryForm'].resetFields();
                this.handleTableSearch();
            },
            /** 处理选择改变（表格区） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /** 重新计算表格（按钮区） */
            redoTableLayout(){
                // 数据加载完成后执行
                this.$nextTick(() => {
                    // 表格重新渲染
                    this.$refs.multipleTable.doLayout();
                });
            },
            /** 更多按钮方法处理（按钮区） */
            handleCommand(command,row){
                // console.log(command)
                // console.log(row)
            },
            // 跳转到数据库页面
            linkDataTable(row) {
              this.$router.push({name: 'DataTable/:id', params: {id: row.id}})
            },
            /** 处理拉取 */
            handlePull() {
              // 网络请求
              pullDataBase(this.$route.params.id).then(res => {
                if (res.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '信息拉取成功！'
                  });
                  // 刷新数据
                  this.handleTableSearch();
                }
              })
            },
            /** 处理返回 */
            handleBack() {
              this.$router.go(-1);
            },
        }
    }
</script>

<style lang="less" scoped>
@import url('~@/assets/style/index.less');

::v-deep .el-form-item {
  margin-bottom: 18px;
}
</style>
