<template>
  <div style="display: inline;margin: 0 9px 0 9px;">
    <el-button @click="clickRoleListSelect" type="success" size="mini" v-permissionDisabled="'system:user:relevancyRole'">
      <slot></slot>
    </el-button>
    <el-dialog v-dialogDrag :title="title" :visible.sync="dialogVisible" style="border-radius: 8px;" width="520px" @close="clickDialogClose" center append-to-body>
      <!-- 信息描述区域 -->
      <el-descriptions :title="showTitle" :column="2" border>
        <template v-slot:extra>
          <el-button type="primary" size="small" @click="clickSelectAll">全选</el-button>
        </template>
        <el-descriptions-item :label="item.name"  v-for="(item,index) in showData" :key="index">
          {{item.value}}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 角色列表区域 -->
      <div style="max-height: 6.1rem;overflow-y: auto;margin-top: 0.25rem;">
        <el-checkbox-group v-model="checkedIds" @change="handleCheckedChange" style="display: flex;flex-direction: column;" size="medium">
          <el-checkbox v-for="item in listData" :label="item.id" :key="item.id">{{item.name+"  ("+item.code+")"}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <!-- 底部按钮区 -->
      <template #footer class="dialog-footer">
			  <el-button type="primary" @click="clickConfirm">确 定</el-button>
			  <el-button @click="clickCancel">取 消</el-button>
		  </template>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 组织关联按钮
 * @author PeterLee
 * @date 2022-06-28
 * 使用范例：<organization-relation-button ref="relationBtn" title="组织关联"
 *   show-title="职位信息" @getDescData="getDescData($event,scope.row)" @getRelationIds="getRelationIds($event,scope.row)" @onClickConfirm="onClickConfirm(arguments,scope.row)" @onClickCancel="onClickCancel">
 *   组织分配</organization-relation-button>
 * 使用方法：
 *   1.@getDescData中获取描述区的内容，callback([{name:'',value:''}])
 *   2.@getRelationIds中获取关联组织ids,callback(ids)
 *   3.@onClickConfirm中callback()回调设置弹出框消失
 *   4.@onClickCancel,@onClickClose都是用callback()回调设置弹出框消失
 */
import {getRoleList} from "@/api/systemApi";

export default {
  name: 'RoleListSelectButton',
  // 父组件传入参数
  props: {
    // 弹出框标题
    title: {
      type: String,
      default : "角色关联"
    },
    // 展示标题
    showTitle: {
      type: String,
      default : "用户信息"
    },
  },
  // 计算操作
  computed: {},
  data() {
    return {
      // 组件内部弹出框展示与否
      dialogVisible: false,
      // 选中的ids
      checkedIds: [],
      // 角色列表数据
      listData: [],
      // 全选
      isSelectAll: false,
      // 组织关联弹出框显示信息（弹出组织树组件）
      showData: [
        {
          name: '名称',
          value: 'simple'
        }
      ],
    }
  },
  methods: {
    // 全选按钮
    clickSelectAll() {
      // 校验是否全选
      this.isSelectAll = this.checkedIds.length === this.listData.length;
      // 全选或者全不选
      this.checkedIds = !this.isSelectAll ? this.getIds(this.listData) : [];
    },
    // 获取ids
    getIds(list){
      const ids=[];
      list.forEach(item =>{
        ids.push(item.id);
      })
      return ids;
    },
    // 点击数据权限按钮
    clickRoleListSelect() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        // 获取描述信息
        this.$emit('getDescData',(data) => {
          this.showData = data;
        });
        this.$emit('getCheckedIds', (checkedIds) => {
          if ( checkedIds!==null && checkedIds!==undefined ) {
            this.checkedIds = checkedIds;
          }else {
            this.checkedIds = [];
          }
        })
        // 创建时获取组织树
        this.getRoleList();
      });
    },
    // 点击取消按钮
    clickCancel() {
      this.$emit('onClickCancel',()=> {
        this.dialogVisible = false;
      });
    },
    // 关闭弹窗时
    clickDialogClose() {
      this.$emit('onClickClose',()=> {
        this.dialogVisible = false;
      });
    },
    // 确认返回选中的组织信息
    clickConfirm() {
      this.$emit('onClickConfirm',this.checkedIds,() =>{
        this.dialogVisible = false;
        this.$message.success("角色分配成功")
      });
    },
    // 勾选改变时
    handleCheckedChange() {
      this.$emit("checkedChange");
    },
    // 查询角色列表
    getRoleList() {
      getRoleList().then(res => {
        if (res.code === 200) {
          this.listData = res.data
        }
      })
    },
  },
  created() {

  },
  // mounted生命周期
  mounted() {

  },
  // 生命周期updated执行时
  updated() {

  }
}
</script>

<style lang="less" scoped>
/* tree border */
.tree-border {
  margin-top: 5px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius: 4px;
}
::v-deep .el-dialog__header {
  padding: 0 20px;
}
::v-deep .el-dialog__footer {
  padding: 0 20px 30px;
}
@import '~@/styles/el-dialog-common.less';
</style>
