<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="系统用户表"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 用户名 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                                <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <!-- 登录名 -->
                        <el-form-item  prop="loginName">
                          <label-tip :tip="columnInfo.loginName.tip" :label="columnInfo.loginName.label" slot="label"/>
                          <el-input v-model="ruleForm.loginName"></el-input>
                        </el-form-item>
                        <!-- 地区代码  关联组织表 -->
                        <el-form-item :label="columnInfo.dqCode.label" prop="dqCode">
                                <el-input v-model="ruleForm.dqCode"></el-input>
                        </el-form-item>
                        <!-- 组织id -->
                        <el-form-item :label="columnInfo.organizationId.label" prop="organizationId">
                                <select-tree v-model="ruleForm.organizationId" :options="options.organizationTreeOptions"
                                       :props="tree.defaultProps"></select-tree>
                        </el-form-item>
                        <!-- 职务id -->
                        <el-form-item :label="columnInfo.positionId.label" prop="positionId">
                                <options-select v-model="ruleForm.positionId" placeholder="请选择" :options="options.positionOptions"></options-select>
                        </el-form-item>
                        <!-- 手机号码 -->
                        <el-form-item :label="columnInfo.mobile.label" prop="mobile">
                                <el-input v-model="ruleForm.mobile"></el-input>
                        </el-form-item>
                        <!-- 邮箱 -->
                        <el-form-item :label="columnInfo.email.label" prop="email">
                                <el-input v-model="ruleForm.email"></el-input>
                        </el-form-item>
                        <!-- 是否开通登录账号  1=是 0=否 -->
                        <el-form-item :label="columnInfo.openAccount.label" prop="openAccount">
                            <enum-dict-select v-model="ruleForm.openAccount" dict-code="binary" module-name="system"></enum-dict-select>
                        </el-form-item>
                        <!-- 状态 1=有效 0=无效 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                            <enum-dict-select v-model="ruleForm.state" dict-code="state" module-name="system"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
    /**
     * 	系统用户表表单
     * 	@author PeterLee
     * 	@since 2022-06-22
     */
    import {mapState, mapMutations, mapActions} from "vuex";

    export default {
        name: 'UserForm',
        //计算操作
        computed: {
            ...mapState({
                //校验规则
                rules: state => state.system.user.rules,
                //表格数据
                tableData: state => state.system.user.tableData,
                //表单信息
                formInfo: state => state.system.user.formInfo,
                //下拉选项
                options: state => state.system.user.options,
                //列信息
                columnInfo: state => state.system.user.columnInfo,
                //树信息
                tree: state => state.system.user.tree,
            })
        },
        data() {
          return {
             
          }
        },
        methods: {
            ...mapMutations({
                setDialogVisible: 'system/user/setDialogVisible',
            }),
            ...mapActions({
                submitAdd: 'system/user/submitAdd',
                submitEdit: 'system/user/submitEdit',
            })
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
