import { render, staticRenderFns } from "./PositionNameLabel.vue?vue&type=template&id=d76fc31a&scoped=true"
import script from "./PositionNameLabel.vue?vue&type=script&lang=js"
export * from "./PositionNameLabel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d76fc31a",
  null
  
)

export default component.exports