<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
                <!-- 右边主要区 -->
                <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                    <!-- 右边头部搜索区 -->
                    <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="110px">
                                <!-- 表id -->
<!--                                <el-form-item :label="columnInfo.tableId.label" prop="tableId">-->
<!--                                        <el-input v-model="queryParams.param.tableId" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 字段名 -->
                                <el-form-item :label="columnInfo.name.label" prop="name">
                                        <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 字段排序 -->
                                <el-form-item :label="columnInfo.ordinalPosition.label" prop="ordinalPosition">
                                        <el-input v-model="queryParams.param.ordinalPosition" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 默认值 -->
                                <el-form-item :label="columnInfo.fieldDefault.label" prop="fieldDefault">
                                        <el-input v-model="queryParams.param.fieldDefault" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 是否为空 -->
                                <el-form-item :label="columnInfo.isNullable.label" prop="isNullable">
                                  <enum-dict-select v-model="queryParams.param.isNullable" module-name="data" dict-code="binary"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                                <!-- 数据类型 -->
                                <el-form-item :label="columnInfo.dataType.label" prop="dataType">
                                        <el-input v-model="queryParams.param.dataType" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 字符最大长度 -->
                                <el-form-item :label="columnInfo.characterMaximumLength.label" prop="characterMaximumLength">
                                        <el-input v-model="queryParams.param.characterMaximumLength" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 数值精度（最大位数） -->
                                <el-form-item :label="columnInfo.numericPrecision.label" prop="numericPrecision">
                                        <el-input v-model="queryParams.param.numericPrecision" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 小数精度 -->
                                <el-form-item :label="columnInfo.numericScale.label" prop="numericScale">
                                        <el-input v-model="queryParams.param.numericScale" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 字段类型 -->
                                <el-form-item :label="columnInfo.fieldType.label" prop="fieldType">
                                        <el-input v-model="queryParams.param.fieldType" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 键类型 -->
                                <el-form-item :label="columnInfo.keyType.label" prop="keyType">
                                        <el-input v-model="queryParams.param.keyType" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 额外说明 -->
                                <el-form-item :label="columnInfo.extra.label" prop="extra">
                                        <el-input v-model="queryParams.param.extra" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 注释 -->
                                <el-form-item :label="columnInfo.fieldComment.label" prop="fieldComment">
                                        <el-input v-model="queryParams.param.fieldComment" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 表单区组件 -->
                                <el-form-item :label="columnInfo.formWidget.label" prop="formWidget">
                                    <options-select v-model="queryParams.param.formWidget" :options="options.formWidgetOptions" size="small"></options-select>
                                </el-form-item>
                                <!-- 搜索区组件 -->
                                <el-form-item :label="columnInfo.searchWidget.label" prop="searchWidget">
                                    <options-select v-model="queryParams.param.searchWidget" :options="options.searchWidgetOptions" size="small"></options-select>
                                </el-form-item>
                                <!-- 表格区组件 -->
                                <el-form-item :label="columnInfo.tableWidget.label" prop="tableWidget">
                                    <options-select v-model="queryParams.param.tableWidget" :options="options.tableWidgetOptions" size="small"></options-select>
                                </el-form-item>
                                <!-- 是否枚举字段 -->
                                <el-form-item :label="columnInfo.isEnum.label" prop="isEnum">
                                  <enum-dict-select v-model="queryParams.param.isEnum" module-name="data" dict-code="binary"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                                <!-- 枚举code -->
                                <el-form-item :label="columnInfo.enumCode.label" prop="enumCode">
                                        <el-input v-model="queryParams.param.enumCode" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 是否列表字段 -->
                                <el-form-item :label="columnInfo.isList.label" prop="isList">
                                  <enum-dict-select v-model="queryParams.param.isList" module-name="data" dict-code="binary"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                                <!-- 列表模块code -->
                                <el-form-item :label="columnInfo.listModuleCode.label" prop="listModuleCode">
                                        <el-input v-model="queryParams.param.listModuleCode" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 列表表code -->
                                <el-form-item :label="columnInfo.listTableCode.label" prop="listTableCode">
                                        <el-input v-model="queryParams.param.listTableCode" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 是否树型 -->
                                <el-form-item :label="columnInfo.isTree.label" prop="isTree">
                                  <enum-dict-select v-model="queryParams.param.isTree" module-name="data" dict-code="binary"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                                <!-- 是否树外置 -->
                                <el-form-item :label="columnInfo.isTreeOut.label" prop="isTreeOut">
                                  <enum-dict-select v-model="queryParams.param.isTreeOut" module-name="data" dict-code="binary"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                                <!-- 状态 -->
                                <el-form-item :label="columnInfo.state.label" prop="state">
                                  <enum-dict-select v-model="queryParams.param.state" module-name="data" dict-code="state"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="handleTableSearch">搜索</el-button>
                            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <!-- 右边中部功能区 -->
                    <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                        <el-col :span="1.5">
                            <el-button
                                    type="primary"
                                    plain
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="clickAdd"
                                    disabled
                                    v-permissionDisabled="'data:field:create'"
                            >新增</el-button>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-import-plus title="数据表字段表" module-name="data" table-name="field" :permission-code="'data:field:import'"></excel-import-plus>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-export-plus file-name="数据表字段表" module-name="data" table-name="field" :select-list="selectList" :permission-code="'data:field:export'"></excel-export-plus>
                        </el-col>
                      <el-col :span="1.5">
                        <el-button
                            type="primary"
                            plain
                            icon="el-icon-download"
                            size="small"
                            @click="handlePull"
                        >拉取
                        </el-button>
                      </el-col>
                      <el-col :span="1.5">
                        <el-button
                            plain
                            size="small"
                            :loading="exportLoading"
                            @click="handleBack"
                        >返回<i class="el-icon-refresh-left el-icon--right"></i></el-button>
                      </el-col>
                        <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                                            :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
                    </el-row>
                    <!-- 右边中部表格区 -->
                    <div class="main">
                        <div class="table">
                            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                      @selection-change="handleSelectionChange" height="100%" :header-cell-style="{background:'#ECF5FF'}">
                                <el-table-column align="center" type="selection" width="55"/>
                                        <!-- 表id -->
<!--                                        <af-table-column align="center" prop="tableId" :label="columnInfo.tableId.label" v-if="columnInfo.tableId.visible"></af-table-column>-->
                                        <!-- 字段名 -->
                                        <af-table-column align="center" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible" width="199"></af-table-column>
                                        <!-- 字段排序 -->
                                        <af-table-column align="center" prop="ordinalPosition" :label="columnInfo.ordinalPosition.label" v-if="columnInfo.ordinalPosition.visible"></af-table-column>
                                        <!-- 默认值 -->
                                        <af-table-column align="center" prop="fieldDefault" :label="columnInfo.fieldDefault.label" v-if="columnInfo.fieldDefault.visible"></af-table-column>
                                        <!-- 是否为空 -->
                                        <af-table-column align="center" prop="isNullable" :label="columnInfo.isNullable.label" v-if="columnInfo.isNullable.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.isNullable" dict-code="binary" module-name="data"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 数据类型 -->
                                        <af-table-column align="center" prop="dataType" :label="columnInfo.dataType.label" v-if="columnInfo.dataType.visible"></af-table-column>
                                        <!-- 字符最大长度 -->
                                        <af-table-column align="center" prop="characterMaximumLength" :label="columnInfo.characterMaximumLength.label" v-if="columnInfo.characterMaximumLength.visible"></af-table-column>
                                        <!-- 数值精度（最大位数） -->
                                        <af-table-column align="center" prop="numericPrecision" :label="columnInfo.numericPrecision.label" v-if="columnInfo.numericPrecision.visible"></af-table-column>
                                        <!-- 小数精度 -->
                                        <af-table-column align="center" prop="numericScale" :label="columnInfo.numericScale.label" v-if="columnInfo.numericScale.visible"></af-table-column>
                                        <!-- 字段类型 -->
                                        <af-table-column align="center" prop="fieldType" :label="columnInfo.fieldType.label" v-if="columnInfo.fieldType.visible"></af-table-column>
                                        <!-- 键类型 -->
                                        <af-table-column align="center" prop="keyType" :label="columnInfo.keyType.label" v-if="columnInfo.keyType.visible"></af-table-column>
                                        <!-- 额外说明 -->
                                        <af-table-column align="center" prop="extra" :label="columnInfo.extra.label" v-if="columnInfo.extra.visible"></af-table-column>
                                        <!-- 注释 -->
                                        <af-table-column align="center" prop="fieldComment" :label="columnInfo.fieldComment.label" v-if="columnInfo.fieldComment.visible"></af-table-column>
                                        <!-- 表单区组件 -->
                                        <af-table-column align="center" prop="formWidget" :label="columnInfo.formWidget.label" v-if="columnInfo.formWidget.visible">
                                          <template v-slot="scope">
                                            <option-name-label v-model="scope.row.formWidget" :options="options.formWidgetOptions"></option-name-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 搜索区组件 -->
                                        <af-table-column align="center" prop="searchWidget" :label="columnInfo.searchWidget.label" v-if="columnInfo.searchWidget.visible">
                                          <template v-slot="scope">
                                            <option-name-label v-model="scope.row.searchWidget" :options="options.searchWidgetOptions"></option-name-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 表格区组件 -->
                                        <af-table-column align="center" prop="tableWidget" :label="columnInfo.tableWidget.label" v-if="columnInfo.tableWidget.visible">
                                          <template v-slot="scope">
                                            <option-name-label v-model="scope.row.tableWidget" :options="options.tableWidgetOptions"></option-name-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 是否枚举字段 -->
                                        <af-table-column align="center" prop="isEnum" :label="columnInfo.isEnum.label" v-if="columnInfo.isEnum.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.isEnum" dict-code="binary" module-name="data"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 枚举code -->
                                        <af-table-column align="center" prop="enumCode" :label="columnInfo.enumCode.label" v-if="columnInfo.enumCode.visible"></af-table-column>
                                        <!-- 是否列表字段 -->
                                        <af-table-column align="center" prop="isList" :label="columnInfo.isList.label" v-if="columnInfo.isList.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.isList" dict-code="binary" module-name="data"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 列表模块code -->
                                        <af-table-column align="center" prop="listModuleCode" :label="columnInfo.listModuleCode.label" v-if="columnInfo.listModuleCode.visible"></af-table-column>
                                        <!-- 列表表code -->
                                        <af-table-column align="center" prop="listTableCode" :label="columnInfo.listTableCode.label" v-if="columnInfo.listTableCode.visible"></af-table-column>
                                        <!-- 是否树型 -->
                                        <af-table-column align="center" prop="isTree" :label="columnInfo.isTree.label" v-if="columnInfo.isTree.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.isTree" dict-code="binary" module-name="data"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                        <!-- 是否树外置 -->
                                        <af-table-column align="center" prop="isTreeOut" :label="columnInfo.isTreeOut.label" v-if="columnInfo.isTreeOut.visible"></af-table-column>
                                        <!-- 状态 -->
                                        <af-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible">
                                          <template v-slot="scope">
                                            <enum-dict-label v-model="scope.row.state" dict-code="state" module-name="data"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </af-table-column>
                                <!-- 操作列 -->
                                <el-table-column align="center" label="操作" width="250" fixed="right">
                                    <template v-slot="scope">
                                        <el-button @click="clickEdit(scope.row)" type="primary" size="mini" v-permissionDisabled="'data:field:update'">配置</el-button>
                                        <el-button @click="clickDel(scope.row)" disabled type="danger" size="mini" v-permissionDisabled="'data:field:remove'">删除</el-button>
                                        <table-more-button :row="scope.row" :button-props="moreButtonProps" @handleCommand="handleCommand"></table-more-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!-- 右边底部分页区 -->
                    <div style="height: 40px;margin-bottom: 0.1rem;">
                        <pagination-plus :totalCount="totalCount"
                                         :param="queryParams"
                                         :pageSizes="pageSizes"
                                         :selectList="selectList"
                                         module-name="data"
                                         table-name="field"
                                         del-disabled
                                         :del-all-permission-code="'data:field:removes'"
                                         :state-permission-code="'data:field:state'"
                                         @handleTableRefresh="handleTableSearch"/>
                    </div>
                </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <FieldForm/>
    </div>
</template>

<script>
/**
 * 数据表字段表主页面 （与自定义组件之间请遵循MVVM架构）
 * @author PeterLee
 * @since 2022-07-18
 */
import FieldForm from "./FieldForm"
import { mapActions, mapMutations, mapState } from "vuex";
import { pullDataField } from "@/api/dataApi";

    export default {
        components: {
            // 表单弹出组件
            FieldForm
        },
        data() {
            return {
                // 每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                // 批量操作选中list（分页控件）
                selectList: [],
                // 展示搜索区（right-toolbar）
                showSearch: false,
                // 导出loading
                exportLoading: false,
                // 编辑数据（弹出框表单）
                editData: {},
                // 是否添加操作（弹出框表单）
                isAdd: false,
            }
        },
        computed: {
            ...mapState({
                // 表格数据
                tableData: state => state.data.field.tableData,
                // 下拉选项
                options: state => state.data.field.options,
                // 共有数据条数（分页控件）
                totalCount: state => state.data.field.totalCount,
                // 列信息
                columnInfo: state => state.data.field.columnInfo,
                // 查询参数
                queryParams: state => state.data.field.queryParams,
                // 时间范围
                dateRange: state => state.data.field.dateRange,
                // 更多按钮信息
                moreButtonProps: state => state.data.field.moreButtonProps,
            })
        },
        // 生命周期create函数
        created() {
            // 获取数据表id
            this.queryParams.param.tableId = this.$route.params.id;
            // 创建时获取所有权限数据（分页查询）
            this.handleTableSearch();
            // 获取OptionList
            this.getOptionList();
        },
        methods: {
            ...mapMutations({
                clickEdit: 'data/field/clickEdit',
                clickAdd: 'data/field/clickAdd',
            }),
            ...mapActions({
                clickDel: 'data/field/handleDel',
                handleTableSearch: 'data/field/handleTableSearch',
                getOptionList: 'data/field/getOptionList',
            }),
            /** 重置按钮点击（搜索区） */
            clickReset() {
                for (let key of Object.keys(this.dateRange)) {
                    this.dateRange[key] = []
                }
                this.$refs['queryForm'].resetFields();
                this.handleTableSearch();
            },
            /** 处理选择改变（表格区） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /** 重新计算表格（按钮区） */
            redoTableLayout() {
                // 数据加载完成后执行
                this.$nextTick(() => {
                    // 表格重新渲染
                    this.$refs.multipleTable.doLayout();
                });
            },
            /** 更多按钮方法处理（按钮区） */
            handleCommand(command,row) {
                // console.log(command)
                // console.log(row)
            },
          /** 处理拉取 */
          handlePull() {
            // 网络请求
            pullDataField(this.$route.params.id).then(res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '信息拉取成功！'
                });
                // 刷新数据
                this.handleTableSearch();
              }
            })
          },
          /** 处理返回 */
          handleBack() {
            this.$router.go(-1)
          },
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');

    ::v-deep .el-form-item {
        margin-bottom: 18px;
    }
</style>
