import { render, staticRenderFns } from "./ServerGroup.vue?vue&type=template&id=18514882&scoped=true"
import script from "./ServerGroup.vue?vue&type=script&lang=js"
export * from "./ServerGroup.vue?vue&type=script&lang=js"
import style0 from "./ServerGroup.vue?vue&type=style&index=0&id=18514882&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18514882",
  null
  
)

export default component.exports