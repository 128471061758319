<!-- 用户消息列表表单 作者：PeterLee 时间：2021-09-24 -->
<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <BaseForm :isAdd="isAdd" :relationDialogVisible.sync="dialogVisible" showTitle="用户消息列表" :showData="showData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" @closeDialog="closeDialog"
                  ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                <el-form-item label="用户id" prop="userId">
                    <el-input v-model="ruleForm.userId"></el-input>
                </el-form-item>
                <el-form-item label="通知id" prop="noticeId">
                    <el-input v-model="ruleForm.noticeId"></el-input>
                </el-form-item>
                <el-form-item label="通知状态" prop="state">
                    <el-input v-model="ruleForm.state"></el-input>
                </el-form-item>
            </template>
        </BaseForm>
    </div>
</template>

<script>
    import BaseForm from '@/components/widget/BaseForm'
    import {addData, updateData} from "@/api/baseApi"
    import {rules} from "./userMessageDataProvider";

    export default {
        name: 'UserMessageForm',
        components: {
            BaseForm
        },
        //父组件传入参数
        props: {
            //是否增加操作（非增加操作会隐藏部分操作）
            isAdd: Boolean,
            //唤醒弹出框
            relationDialogVisible: Boolean,
            //回显数据
            showData: Object,
        },
        //计算操作
        computed: {},
        data() {
            return {
                //组件内部弹出框展示与否
                dialogVisible: false,
                // 表单校验规则
                rules: rules
            }
        },
        watch: {
            //监视父组件是否有指令需要重置页面
            relationDialogVisible: {
                handler: function (newVal, oldVal) {
                    if (newVal) {
                        //更新时刷新表单校验信息
                        this.dialogVisible = true;
                        //更新回显数据
                        this.$nextTick(() => {

                        });
                    }
                }
            }
        },
        created() {

        },
        methods: {
            //添加提交后执行内容
            submitAdd(data) {
                addData("system","userMessage", data).then(res => {
                    if (res.code === 200) {
                        //关闭弹出层并刷新树
                        this.$parent.refreshData();
                        this.$message.success("添加用户消息列表成功")
                        this.$refs.baseForm.setVisible(false);
                    }
                });
            },
            //编辑提交后执行内容
            submitEdit(data) {
                updateData("system","userMessage", data).then(res => {
                    if (res.code === 200) {
                        //关闭弹出层并刷新树
                        this.$parent.refreshData();
                        this.$message.success("修改用户消息列表信息成功")
                        this.$refs.baseForm.setVisible(false);
                    }
                });
            },
            //关闭弹出层
            closeDialog() {
                this.$emit("update:relationDialogVisible", false);
            }
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
