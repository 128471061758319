<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
                <!--右边主要区-->
                <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                    <!--右边头部搜索区-->
                    <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="110px">
                                <!-- 角色名 -->
                                <el-form-item :label="columnInfo.name.label" prop="name">
                                        <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 角色标识 -->
                                <el-form-item :label="columnInfo.code.label" prop="code">
                                        <el-input v-model="queryParams.param.code" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 角色描述 -->
                                <el-form-item :label="columnInfo.description.label" prop="description">
                                        <el-input v-model="queryParams.param.description" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 角色类型 0默认角色 1自建角色 -->
                                <el-form-item :label="columnInfo.type.label" prop="type">
                                        <el-input v-model="queryParams.param.type" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 数据权限范围 -->
<!--                                <el-form-item :label="columnInfo.dataScope.label" prop="dataScope">-->
<!--                                        <el-input v-model="queryParams.param.dataScope" placeholder="请输入内容" size="small" clearable></el-input>-->
<!--                                </el-form-item>-->
                                <!-- 状态 0：无效 1：有效 -->
                                <el-form-item :label="columnInfo.state.label" prop="state">
                                        <enum-dict-select v-model="queryParams.param.state" dict-code="state" module-name="system" size="small"></enum-dict-select>
                                </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="handleTableSearch">搜索</el-button>
                            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <!--右边中部功能区-->
                    <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                        <el-col :span="1.5">
                            <el-button
                                    type="primary"
                                    plain
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="clickAdd"
                                    v-permissionDisabled="'system:role:create'"
                            >新增</el-button>
                        </el-col>
<!--                        <el-col :span="1.5">-->
<!--                            <excel-import-plus title="系统角色表" module-name="system" table-name="role" :permission-code="'system:role:import'"></excel-import-plus>-->
<!--                        </el-col>-->
<!--                        <el-col :span="1.5">-->
<!--                            <excel-export-plus file-name="系统角色表" module-name="system" table-name="role" :select-list="selectList" :permission-code="'system:role:export'"></excel-export-plus>-->
<!--                        </el-col>-->
                        <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                                            :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
                    </el-row>
                    <!--右边中部表格区-->
                    <div class="main">
                        <div class="table">
                            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                      @selection-change="handleSelectionChange" height="100%" :header-cell-style="{background:'#ECF5FF'}">
                                <el-table-column align="center" type="selection" width="55"/>
                                        <!-- 角色名 -->
                                        <af-table-column align="center" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible"></af-table-column>
                                        <!-- 角色标识 -->
                                        <af-table-column align="center" prop="code" :label="columnInfo.code.label" v-if="columnInfo.code.visible"></af-table-column>
                                        <!-- 角色描述 -->
                                        <af-table-column align="center" prop="description" :label="columnInfo.description.label" v-if="columnInfo.description.visible"></af-table-column>
                                        <!-- 角色类型 0默认角色 1自建角色 -->
                                        <el-table-column align="center" prop="type" :label="columnInfo.type.label" v-if="columnInfo.type.visible">
                                          <template slot-scope="scope">
                                            <enum-dict-label v-model="scope.row.type" module-name="system" dict-code="roleType"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </el-table-column>
<!--                                        &lt;!&ndash; 数据权限范围 &ndash;&gt;-->
<!--                                        <el-table-column align="center" prop="dataScope" :label="columnInfo.dataScope.label" v-if="columnInfo.dataScope.visible">-->
<!--                                          <template slot-scope="scope">-->
<!--                                            <enum-dict-label v-model="scope.row.dataScope" module-name="system" dict-code="dataScope"-->
<!--                                                             show-tag></enum-dict-label>-->
<!--                                          </template>-->
<!--                                        </el-table-column>-->
                                        <!-- 状态 0：无效 1：有效 -->
                                        <el-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible">
                                          <template slot-scope="scope">
                                            <enum-dict-label v-model="scope.row.state" module-name="system" dict-code="state"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </el-table-column>
                                <!-- 操作列 -->
                                <el-table-column align="center" label="操作" width="345">
                                    <template slot-scope="scope">
                                      <el-button @click="clickEdit(scope.row)" type="primary" size="mini" v-permissionDisabled="'system:role:update'">编辑</el-button>
                                      <el-button @click="clickDel(scope.row)" type="danger" size="mini" v-permissionDisabled="'system:role:remove'">删除</el-button>
                                      <menu-select-button v-model="scope.row" >菜单权限</menu-select-button>
                                      <data-scope-select-button v-model="scope.row" >数据权限</data-scope-select-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!--右边底部分页区-->
                    <div style="height: 40px;margin-bottom: 0.1rem;">
                        <pagination-plus :totalCount="totalCount"
                                         :param="queryParams"
                                         :pageSizes="pageSizes"
                                         :selectList="selectList"
                                         module-name="system"
                                         table-name="role"
                                         :del-all-permission-code="'system:role:removes'"
                                         :state-permission-code="'system:role:state'"
                                         @handleTableRefresh="handleTableSearch"/>
                    </div>
                </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <RoleForm/>
    </div>
</template>

<script>
    /**
     * 系统角色表主页面 （与自定义组件之间请遵循MVVM架构）
     * @author PeterLee
     * @since 2022-03-26
     */
    import RoleForm from "./RoleForm"
    import { mapState, mapMutations, mapActions } from "vuex"
    import DataScopeSelectButton from "./widget/DataScopeSelect"
    import MenuSelectButton from "./widget/MenuSelect"

    export default {
        components: {
            //表单弹出组件
            RoleForm,
            DataScopeSelectButton,
            MenuSelectButton
        },
        data() {
            return {
                //每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                //批量操作选中list（分页控件）
                selectList: [],
                //展示搜索区（right-toolbar）
                showSearch: false,
                //导出loading
                exportLoading: false,
            }
        },
        computed: {
            ...mapState({
                //表格数据
                tableData: state => state.system.role.tableData,
                //下拉选项
                options: state => state.system.role.options,
                //共有数据条数（分页控件）
                totalCount: state => state.system.role.totalCount,
                //列信息
                columnInfo: state => state.system.role.columnInfo,
                //查询参数
                queryParams: state => state.system.role.queryParams,
                //时间范围
                dateRange: state => state.system.role.dateRange,
                //更多按钮信息
                moreButtonProps: state => state.system.role.moreButtonProps,
            })
        },
        //生命周期create函数
        created() {
            //创建时获取所有权限数据（分页查询）
            this.handleTableSearch();
            //获取OptionList
            this.getOptionList();
        },
        methods: {
            ...mapMutations({
                clickEdit: 'system/role/clickEdit',
                clickAdd: 'system/role/clickAdd',
            }),
            ...mapActions({
                clickDel: 'system/role/handleDel',
                handleTableSearch: 'system/role/handleTableSearch',
                getOptionList: 'system/role/getOptionList',
            }),
            /** 重置按钮点击（搜索区） */
            clickReset() {
                for (let key of Object.keys(this.dateRange)) {
                    this.dateRange[key] = []
                }
                this.$refs['queryForm'].resetFields();
                this.handleTableSearch();
            },
            /** 处理选择改变（表格区） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /** 重新计算表格（按钮区） */
            redoTableLayout() {
                // 数据加载完成后执行
                this.$nextTick(() => {
                    // 表格重新渲染
                    this.$refs.multipleTable.doLayout();
                });
            },
            /** 更多按钮方法处理（按钮区） */
            handleCommand(command) {
                if(command === 'dataScope') {

                }
                if(command === 'menuPermission') {

                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');

    ::v-deep .el-form-item {
        margin-bottom: 18px;
    }
</style>
