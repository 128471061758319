<template>
  <div>
    <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
    <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="数据表字段表"
                    :showData="formInfo.editData"
                    :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <!-- 表id -->
        <!--                        <el-form-item :label="columnInfo.tableId.label" prop="tableId">-->
        <!--                                <el-input v-model="ruleForm.tableId"></el-input>-->
        <!--                        </el-form-item>-->
        <!-- 字段名 -->
        <el-form-item :label="columnInfo.name.label" prop="name">
          <el-input v-model="ruleForm.name" disabled></el-input>
        </el-form-item>
        <!-- 字段排序 -->
        <el-form-item :label="columnInfo.ordinalPosition.label" prop="ordinalPosition">
          <el-input v-model="ruleForm.ordinalPosition" disabled></el-input>
        </el-form-item>
        <!-- 默认值 -->
        <el-form-item :label="columnInfo.fieldDefault.label" prop="fieldDefault">
          <el-input v-model="ruleForm.fieldDefault" disabled></el-input>
        </el-form-item>
        <!-- 是否为空 -->
        <el-form-item :label="columnInfo.isNullable.label" prop="isNullable">
          <enum-dict-select v-model="ruleForm.isNullable" module-name="data" dict-code="binary"
                            disabled></enum-dict-select>
        </el-form-item>
        <!-- 数据类型 -->
        <el-form-item :label="columnInfo.dataType.label" prop="dataType">
          <el-input v-model="ruleForm.dataType" disabled></el-input>
        </el-form-item>
        <!-- 字符最大长度 -->
        <el-form-item :label="columnInfo.characterMaximumLength.label" prop="characterMaximumLength">
          <el-input v-model="ruleForm.characterMaximumLength" disabled></el-input>
        </el-form-item>
        <!-- 数值精度（最大位数） -->
        <el-form-item :label="columnInfo.numericPrecision.label" prop="numericPrecision">
          <el-input v-model="ruleForm.numericPrecision" disabled></el-input>
        </el-form-item>
        <!-- 小数精度 -->
        <el-form-item :label="columnInfo.numericScale.label" prop="numericScale">
          <el-input v-model="ruleForm.numericScale" disabled></el-input>
        </el-form-item>
        <!-- 字段类型 -->
        <el-form-item :label="columnInfo.fieldType.label" prop="fieldType">
          <el-input v-model="ruleForm.fieldType" disabled></el-input>
        </el-form-item>
        <!-- 键类型 -->
        <el-form-item :label="columnInfo.keyType.label" prop="keyType">
          <el-input v-model="ruleForm.keyType" disabled></el-input>
        </el-form-item>
        <!-- 额外说明 -->
        <el-form-item :label="columnInfo.extra.label" prop="extra">
          <el-input v-model="ruleForm.extra" disabled></el-input>
        </el-form-item>
        <!-- 注释 -->
        <el-form-item :label="columnInfo.fieldComment.label" prop="fieldComment">
          <el-input v-model="ruleForm.fieldComment" disabled></el-input>
        </el-form-item>
        <!-- 表单区组件 -->
        <el-form-item :label="columnInfo.formWidget.label" prop="formWidget" v-show="!treeShow && !listShow && !enumShow">
          <options-select v-model="ruleForm.formWidget" :options="options.formWidgetOptions"></options-select>
        </el-form-item>
        <!-- 搜索区组件 -->
        <el-form-item :label="columnInfo.searchWidget.label" prop="searchWidget" v-show="!treeShow && !listShow && !enumShow">
          <options-select v-model="ruleForm.searchWidget" :options="options.searchWidgetOptions"></options-select>
        </el-form-item>
        <!-- 表格区组件 -->
        <el-form-item :label="columnInfo.tableWidget.label" prop="tableWidget" v-show="!treeShow && !listShow && !enumShow">
          <options-select v-model="ruleForm.tableWidget" :options="options.tableWidgetOptions"></options-select>
        </el-form-item>
        <!-- 是否枚举字段 -->
        <el-form-item :label="columnInfo.isEnum.label" prop="isEnum" v-show="!listShow && !treeShow">
          <enum-dict-select v-model="ruleForm.isEnum" module-name="data" dict-code="binary"
                            @change="enumChange(ruleForm.isEnum)"></enum-dict-select>
        </el-form-item>
        <!-- 枚举code -->
        <el-form-item :label="columnInfo.enumCode.label" prop="enumCode" v-show="enumShow">
          <el-input v-model="ruleForm.enumCode"></el-input>
        </el-form-item>
        <!-- 是否树型 -->
        <el-form-item :label="columnInfo.isTree.label" prop="isTree" v-show="!enumShow && !listShow">
          <enum-dict-select v-model="ruleForm.isTree" module-name="data" dict-code="binary"
                            @change="treeChange(ruleForm.isTree)"></enum-dict-select>
        </el-form-item>
        <!-- 是否外置树 -->
        <el-form-item :label="columnInfo.isTreeOut.label" prop="isTreeOut" v-show="treeShow">
          <enum-dict-select v-model="ruleForm.isTreeOut" module-name="data" dict-code="binary"></enum-dict-select>
        </el-form-item>
        <!-- 是否列表字段 -->
        <el-form-item :label="columnInfo.isList.label" prop="isList" v-show="!enumShow && !treeShow">
          <enum-dict-select v-model="ruleForm.isList" module-name="data" dict-code="binary"
                            @change="listChange(ruleForm.isList)"></enum-dict-select>
        </el-form-item>
        <!-- 列表模块code -->
        <el-form-item :label="columnInfo.listModuleCode.label" prop="listModuleCode" v-show="listShow||treeShow">
          <el-input v-model="ruleForm.listModuleCode"></el-input>
        </el-form-item>
        <!-- 列表表code -->
        <el-form-item :label="columnInfo.listTableCode.label" prop="listTableCode" v-show="listShow||treeShow">
          <el-input v-model="ruleForm.listTableCode"></el-input>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item :label="columnInfo.state.label" prop="state">
          <enum-dict-select v-model="ruleForm.state" module-name="data" dict-code="state"></enum-dict-select>
        </el-form-item>
      </template>
    </base-form-plus>
  </div>
</template>

<script>
/**
 *  数据表字段表表单
 *  @author PeterLee
 *  @since 2022-07-18
 */
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'FieldForm',
  data() {
    return {
      // 控制枚举相关展示
      enumShow: false,
      // 控制list相关展示
      listShow: false,
      // 控制tree相关展示
      treeShow: false
    }
  },
  // 计算操作
  computed: {
    ...mapState({
      // 校验规则
      rules: state => state.data.field.rules,
      // 表格数据
      tableData: state => state.data.field.tableData,
      // 表单信息
      formInfo: state => state.data.field.formInfo,
      // 下拉选项
      options: state => state.data.field.options,
      // 列信息
      columnInfo: state => state.data.field.columnInfo,
    })
  },
  methods: {
    ...mapMutations({
      setDialogVisible: 'data/field/setDialogVisible',
    }),
    ...mapActions({
      submitAdd: 'data/field/submitAdd',
      submitEdit: 'data/field/submitEdit',
    }),
    // 是否枚举改变
    enumChange(val) {
      val === '0' ? this.enumShow = false : this.enumShow = true;
    },
    // 是否list改变
    listChange(val) {
      val === '0' ? this.listShow = false : this.listShow = true;
    },
    // 是否tree改变
    treeChange(val) {
      val === '0' ? this.treeShow = false : this.treeShow = true;
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>
