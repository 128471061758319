<template>
  <div style="display: inline-block;margin: 0 8px;">
    <el-button @click="clickCodePreviewButton" type="success" size="mini">
      <slot></slot>
    </el-button>
    <el-dialog :visible.sync="dialogVisible" width="1020px" @close="clickDialogClose" center
               append-to-body top="30px">
      <!-- 代码选项区域 -->
      <el-tabs type="border-card">
        <el-tab-pane v-for="item in previewCodes" :label="item.key.split('/').pop()">
          <div style="color: red;">
            文件生成路径：{{item.key.substring(0,item.key.length-(item.key.split('/').pop()).length-1)}}
          </div>
          <pre v-highlight="item.code">
            <code style="overflow: auto;height: 666px;"></code>
          </pre>
        </el-tab-pane>
      </el-tabs>
      <!-- 底部按钮区 -->
      <template #footer class="dialog-footer">
          <code-confirm-button :preview-codes="previewCodes">生成</code-confirm-button>
			    <el-button @click="clickCancel">取 消</el-button>
		  </template>
    </el-dialog>
  </div>
</template>

<script>
import { codePreview } from "@/api/dataApi";
import codeConfirmButton from "@/views/data/table/components/CodeConfirmButton";

/**
 * 组织关联按钮
 *
 * @author PeterLee
 * @date 2022-07-28
 */
export default {
  name: 'CodePreviewButton',
  components: {
    codeConfirmButton
  },
  // 父组件传入参数
  props: {
    tableId: String,
  },
  // 计算操作
  computed: {},
  data() {
    return {
      // 组件内部弹出框展示与否
      dialogVisible: false,
      previewCodes:[],
    }
  },
  methods: {
    // 点击数据权限按钮
    clickCodePreviewButton() {
      this.$nextTick(() => {
        // 获取代码内容
        codePreview(this.tableId).then(res => {
            if (res.code === 200) {
               this.previewCodes = res.data;
               this.dialogVisible = true;
            }
        })
      })
    },
    // 点击取消按钮
    clickCancel() {
      this.dialogVisible = false;
    },
    // 关闭弹窗时
    clickDialogClose() {
      this.dialogVisible = false;
    },
  },
  created() {

  },
  // mounted生命周期
  mounted() {

  },
  // 生命周期updated执行时
  updated() {

  }
}
</script>

<style lang="less" scoped>
/* tree border */
.tree-border {
  margin-top: 5px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius: 4px;
}
::v-deep .el-dialog__body {
   padding-bottom: 0;
}
::v-deep .el-dialog__footer {
   padding: 0 20px 30px;
}
</style>
