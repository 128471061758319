<!-- 系统通知内容表页面 作者：PeterLee 时间：2021-09-24 -->
<template>
    <div style="height: 100%;">
        <el-container class="container">
            <el-header class="header" height="auto">
                <div class="search">
                    <span>通知类型 ： </span>
                    <el-input v-model="check.type" placeholder="请输入内容"></el-input>
                </div>
                <div class="search">
                    <span>前往处理路径 ：</span>
                    <el-input v-model="check.toPath" placeholder="请输入内容"></el-input>
                </div>
                <div class="search">
                    <span>回执跳转路径 ：</span>
                    <el-input v-model="check.backPath" placeholder="请输入内容"></el-input>
                </div>
                <div class="search">
                    <span>通知标题 ：</span>
                    <el-input v-model="check.title" placeholder="请输入内容"></el-input>
                </div>
                <div class="search">
                    <span>状态 ： </span>
                    <el-input v-model="check.state" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <el-button type="primary" @click="handleTableCheck()">查询</el-button>
                    <el-button type="success" @click="clickAdd()">新增</el-button>
                </div>
            </el-header>
            <el-main class="main">
                <div class="table">
                    <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                              @selection-change="handleSelectionChange">
                        <el-table-column align="center" type="selection" width="55"/>
                        <el-table-column align="center" prop="type" label="通知类型"></el-table-column>
                        <el-table-column align="center" prop="toPath" label="前往处理路径"></el-table-column>
                        <el-table-column align="center" prop="backPath" label="回执跳转路径"></el-table-column>
                        <el-table-column align="center" prop="title" label="通知标题"></el-table-column>
                        <el-table-column align="center" prop="detail" label="内容详情（富文本）"></el-table-column>
                        <el-table-column align="center" prop="state" label="状态"></el-table-column>
                        <el-table-column align="center" label="操作" width="155">
                            <template slot-scope="scope">
                                <el-button @click="clickEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                                <el-button @click="clickDelete(scope.row)" type="danger" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-main>
            <div class="bottom">
                <Pagination :totalCount="totalCount" :pageSize="pageSize" :currPage="currPage" :pageSizes="pageSizes" :selectList="selectList"
                              @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
                              @handleDelAll="handleDelAll" @handleState="handleState"/>
            </div>
        </el-container>
        <!-- 弹出层组件 -->
        <NoticeForm :relationDialogVisible.sync="dialogVisible" :showData="editData" :isAdd="isAdd"/>
    </div>
</template>

<script>
    //引入角色表单放入弹出层
    import NoticeForm from "./NoticeForm"
    import Pagination from "@/components/widget/Pagination"
    import {removeById,getPage,updateState,removeData} from "@/api/baseApi"

    export default {
        components: {
            Pagination,
            NoticeForm
        },
        data() {
            return {
                //检索数据
                check: {},
                //表格数据
                tableData: [],
                //弹出框显示控制（弹出框表单）
                dialogVisible: false,
                //编辑数据（弹出框表单）
                editData: {},
                //是否添加操作（弹出框表单）
                isAdd: false,
                //共有数据条数（分页控件）
                totalCount: 0,
                //当前页数（分页控件）
                currPage: 1,
                //每页条数（分页控件）
                pageSize: 10,
                //每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                //批量操作选中list（分页控件）
                selectList: [],
            }
        },
        //生命周期create函数
        created() {
            //创建时获取所有权限数据（分页查询）
            this.handleTableCheck();
        },
        methods: {
            /*************************************操作触发事件***********************************/
            //点击批量删除时(分页组件)
            handleDelAll(array) {
                removeData("system","notice",array).then(res => {
                    if (res.code === 200) {
                        //刷新页面数据
                        this.refreshData()
                        //顶部显示提示
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                })
            },
            //点击启用禁用时,disabled==true为禁用(分页组件)
            handleState(disabled,array) {
                //此处0为禁用，1为启用（根据自己代码情况进行调整）
                const state=disabled?0:1;
                let param = {
                    ids:array,
                    state:state
                }
                //更新状态
                updateState("system","notice",param).then(res => {
                    if (res.code === 200) {
                        //刷新页面数据
                        this.refreshData()
                        //顶部显示提示
                        this.$message({
                            type: 'success',
                            message: '状态修改成功!'
                        });
                    }
                })
            },
            //点击添加节点
            clickAdd() {
                this.isAdd = true
                this.dialogVisible = true;
            },
            //点击删除操作（表格）
            clickDelete(data) {
                this.dataDel(data.id);
            },
            //点击编辑操作（表格）
            clickEdit(data) {
                this.isAdd = false
                this.editData = data
                this.dialogVisible = true;
            },
            //处理选择改变（表格）
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /*************************************数据处理事件***********************************/
            //刷新页面数据(必须要有，否则子组件会不可用)
            refreshData() {
                //刷新表格
                this.handleTableCheck()
            },
            //处理表格查询
            handleTableCheck() {
                let param = {
                    currPage: this.currPage,
                    limit: this.pageSize,
                    ascOrder: false,
                    orderField: "",
                    //此处为可能需要修改部分，请不要忽略
                    queryMap: {
                        'type': this.check.type,
                        'to_path': this.check.toPath,
                        'back_path': this.check.backPath,
                        'title': this.check.title,
                        'detail': this.check.detail,
                        'state': this.check.state,
                    }
                }
                this.getDataPage(param);
            },
            //删除
            dataDel(id) {
                //弹出确认框
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //确认点击删除后，请求后端
                    removeById("system","notice",id).then(res => {
                        if (res.code === 200) {
                            //刷新页面数据
                            this.refreshData()
                            //顶部显示提示
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            /*************************************数据请求事件***********************************/
            //分页查询（表格）（分页）
            getDataPage(param) {
                getPage("system","notice",param).then(res => {
                    if (res.code === 200) {
                        this.tableData = res.data.list;
                        this.totalCount = res.data.totalCount;
                    }
                })
            },
            /*************************************组件回调事件***********************************/
            //当每页展示条数发生变化时，重新请求,页面转回第一页(分页组件)
            handleSizeChange(pageSize) {
                this.pageSize = pageSize;
                this.refreshData();
            },
            //当当前页发生变化时，重新请求(分页组件)
            handleCurrentChange(currentPage) {
                this.currPage = currentPage;
                //未选中，全文读取
                this.refreshData();
            }
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');
</style>
