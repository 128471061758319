/**
 * 本文件存放在form表单和index页面中使用的常量数据，作为相关页面的"数据提供者"
 * 参考链接（Alt/Ctrl+单击点开）：https://element.eleme.cn/#/zh-CN/component/form
 * 用户消息列表数据提供者 作者：PeterLee 时间：2021-09-24
 */
//表单数据校验规则
const rules = {
    userId:[{
         required: true,
         message: '请输入用户id',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    noticeId:[{
         required: true,
         message: '请输入通知id',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    state:[{
         required: true,
         message: '请输入通知状态 (0:待发送，1：待查看，2：待回执，4：已完成)',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
}
//下拉框选项(没有下拉框选项可以删除，此处只为范例)
const options={

}
export {
    rules,
    options
}
