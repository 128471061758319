/**
 * 本文件存放在form表单和index页面中使用的常量数据，作为相关页面的"数据提供者"
 * 参考链接（Alt/Ctrl+单击点开）：https://element.eleme.cn/#/zh-CN/component/form
 * 文件服务器表数据提供者 作者：PeterLee 时间：2021-11-22
 */
//表单数据校验规则
const rules = {
    name:[{
         required: true,
         message: '请输入别名',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    group:[{
         required: true,
         message: '请输入所属组号',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    peerId:[{
         required: true,
         message: '请输入组内编号',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    enableHttps:[{
         required: true,
         message: '请输入是否开启https',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    host:[{
         required: true,
         message: '请输入本机地址',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    state:[{
         required: true,
         message: '请输入状态',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
}
//列信息
const columnInfo={
    name:{
        visible:true,
        label:"别名"
    },
    group:{
        visible:true,
        label:"所属组号"
    },
    peerId:{
        visible:true,
        label:"组内编号"
    },
    enableHttps:{
        visible:true,
        label:"是否开启https"
    },
    host:{
        visible:true,
        label:"本机地址"
    },
    state:{
        visible:true,
        label:"状态"
    },
}

//下拉框选项(没有下拉框选项可以删除，此处只为范例)
const options={

}
//时间范围
const dateRange= {
}
//查询参数
const queryParams={
    //当前页数（分页控件）
    currPage: 1,
    //每页条数（分页控件）
    limit: 10,
    //是否升序
    ascOrder: false,
    //排序字段
    orderField: "",
    //此处为可能需要修改部分，请不要忽略
    param: {
        name: undefined,
        group: undefined,
        peerId: undefined,
        enableHttps: undefined,
        host: undefined,
        state: undefined,
    }
}
export {
    rules,
    options,
    queryParams,
    columnInfo,
    dateRange
}
