<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" width="25%" @close="clickDialogClose" center>
		<!-- 信息描述区域 -->
		<el-descriptions :title="showTitle" :column="2" border>
			<template slot="extra">
				<el-button type="primary" size="small" @click="clickSelectAll">全选</el-button>
			</template>
			<el-descriptions-item v-for="(item,index) in showData" :key="index">
				<template slot="label">
					{{item.name}}
				</template>
				{{item.value}}
			</el-descriptions-item>
		</el-descriptions>
		<!-- 角色列表区域 -->
		<div style="max-height: 4rem;overflow-y: auto;margin-top: 0.25rem;">
			<el-checkbox-group v-model="checkedIds" @change="handleCheckedChange" style="display: flex;flex-direction: column;" size="medium">
			    <el-checkbox v-for="item in listData" :label="item.id" :key="item.id">{{item.name+"  ("+item.code+")"}}</el-checkbox>
			</el-checkbox-group>
		</div>
		<!-- 底部按钮区 -->
		<template #footer class="dialog-footer">
			<el-button type="primary" @click="clickConfirm">确 定</el-button>
			<el-button @click="dialogVisible = false">取 消</el-button>
		</template>
	</el-dialog>
</template>

<script>
	export default {
		//父组件传入参数
		props: {
			//唤醒弹出框
			relationDialogVisible: Boolean,
			//展示标题
			// showTitle: String,
			//展示数据（name:value）
			showData: Array,
			// //用户组织信息
			// organizationId:String,
			//默认选中数据ids
			defaultCheckedIds: Array,
		},
		//计算操作
		computed: {
			
		},
		data() {
			return {
				//组件内部弹出框展示与否
				dialogVisible: false,
				//展示标题
				showTitle: '用户信息',
				//弹出框标题
				title: '角色分配',
				//角色列表数据
				listData: [],
				//子父级是否不关联
				isSelectAll:false,
				//选中的ids
				checkedIds:[],
			}
		},
		watch: {
			//监视父组件是否有指令需要重置页面
			relationDialogVisible: {
				handler: function(newVal, oldVal) {
					if (newVal) {
						//更新时刷新表单校验信息
						this.dialogVisible = true;
					}
				}
			},
			defaultCheckedIds:{
				handler:function(newVal,oldVal){
					if(newVal){
						//把传入的默认选项赋值给checkedIds
						this.checkedIds=this.defaultCheckedIds;
						//创建时获取用户组织授权的角色列表（保证先拿到选中项数据）
						this.getRoleListByOrganizationId();
					}
				}
			}
		},
		methods: {
			//勾选改变时
			handleCheckedChange(){
				
			},
			//关闭弹窗时
			clickDialogClose() {
				this.$emit("update:relationDialogVisible", false);
				this.checkedIds=[];
				this.dialogVisible=false;
			},
			//查询该用户组织下的角色列表
			getRoleListByOrganizationId() {
				this.$systemApi.getRoleListByOrganizationId().then(res => {
					if (res.code === 200) {
						this.listData = res.data
						//更新默认勾选
						this.$nextTick(() => {
						    this.checkedIds=this.defaultCheckedIds;
						});
					}
				})
			},
			//确认返回选中的角色信息
			clickConfirm(){
				this.$emit('dialogConfirmBack',this.checkedIds)
				this.dialogVisible=false
			},
			//全选按钮
			clickSelectAll(){
				//校验是否全选
				if(this.checkedIds.length===this.listData.length){
					this.isSelectAll=true;
				}else{
					this.isSelectAll=false;
				}
				//全选或者全不选
				this.checkedIds = !this.isSelectAll ? this.getIds(this.listData) : [];
			},
			//获取ids
			getIds(list){
				const ids=[];
				list.forEach(item =>{
					ids.push(item.id);
				})
				return ids;
			},
			//获取用户关联的角色ids
			getRoleIdsByUserId(id){
				this.$systemApi.getRoleIdsByUserId(id).then(res => {
						if(res.code===200){
							this.checkedIds=res.data;
						}
				})
			},
		},
		created() {
			
		},
		mounted() {
			
		},
		//生命周期updated执行时
		updated() {
			
		}
	}
</script>

<style lang="less" scoped>

</style>
