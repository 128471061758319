<!-- 文件服务器组表页面 作者：PeterLee 时间：2021-11-22 -->
<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
            <!--右边主要区-->
            <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                <!--右边头部搜索区-->
                <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="68px">
                    <el-form-item :label="columnInfo.name.label" prop="name">
                            <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="columnInfo.code.label" prop="code">
                            <el-input v-model="queryParams.param.code" placeholder="请输入内容" size="small" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="columnInfo.address.label" prop="address">
                            <el-input v-model="queryParams.param.address" placeholder="请输入内容" size="small" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="columnInfo.domain.label" prop="domain">
                            <el-input v-model="queryParams.param.domain" placeholder="请输入内容" size="small" clearable></el-input>
                    </el-form-item>
                    <el-form-item :label="columnInfo.state.label" prop="state">
                            <el-input v-model="queryParams.param.state" placeholder="请输入内容" size="small" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
                        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </el-form>
                <!--右边中部功能区-->
                <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                    <el-col :span="1.5">
                        <el-button
                                type="primary"
                                plain
                                icon="el-icon-plus"
                                size="small"
                                @click="handleAdd"
                        >新增</el-button>
                    </el-col>
<!--                    <el-col :span="1.5">-->
<!--                        <el-button-->
<!--                                type="info"-->
<!--                                plain-->
<!--                                icon="el-icon-upload2"-->
<!--                                size="small"-->
<!--                                @click="handleImport"-->
<!--                        >导入</el-button>-->
<!--                    </el-col>-->
<!--                    <el-col :span="1.5">-->
<!--                        <el-button-->
<!--                                type="warning"-->
<!--                                plain-->
<!--                                icon="el-icon-download"-->
<!--                                size="small"-->
<!--                                :loading="exportLoading"-->
<!--                                @click="handleExport"-->
<!--                        >导出</el-button>-->
<!--                    </el-col>-->
                    <right-toolbar :showSearch.sync="showSearch" @queryTable="handleTableCheck" :columnInfo="columnInfo"></right-toolbar>
                </el-row>
                <!--右边中部表格区-->
                <div class="main">
                    <div class="table">
                        <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                  @selection-change="handleSelectionChange">
                            <el-table-column align="center" type="selection" width="55"/>
                                    <el-table-column align="center" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible"></el-table-column>
                                    <el-table-column align="center" prop="code" :label="columnInfo.code.label" v-if="columnInfo.code.visible"></el-table-column>
                                    <el-table-column align="center" prop="address" :label="columnInfo.address.label" v-if="columnInfo.address.visible"></el-table-column>
                                    <el-table-column align="center" prop="domain" :label="columnInfo.domain.label" v-if="columnInfo.domain.visible"></el-table-column>
                                    <el-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible"></el-table-column>
                            <el-table-column align="center" label="操作" width="255">
                                <template slot-scope="scope">
                                    <el-button @click="clickEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                                    <el-button @click="clickDelete(scope.row)" type="danger" size="mini">删除</el-button>
                                    <el-button @click="clickEdit(scope.row)" type="success" size="mini">服务器管理</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <!--右边底部分页区-->
                <div style="height: 40px;margin-bottom: 0.1rem;">
                    <Pagination :totalCount="totalCount" :pageSize="queryParams.limit" :currPage="queryParams.currPage" :pageSizes="pageSizes"
                                :selectList="selectList"
                                @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
                                @handleDelAll="handleDelAll" @handleState="handleState"/>
                </div>
            </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <ServerGroupForm :relationDialogVisible.sync="dialogVisible" :showData="editData" :isAdd="isAdd"/>
    </div>
</template>

<script>
    //引入角色表单放入弹出层
    import ServerGroupForm from "./ServerGroupForm"
    import Pagination from "@/components/widget/Pagination"
    import {removeById,getPage,updateState,removeData,getList} from "@/api/baseApi"
    import {options,columnInfo,dateRange,queryParams} from "./serverGroupDataProvider";

    export default {
        components: {
            Pagination,
            ServerGroupForm
        },
        data() {
            return {
                //下拉选项
                options: options,
                //列信息
                columnInfo:columnInfo,
                //时间范围
                dateRange:dateRange,
                //查询参数
                queryParams:queryParams,
                //表格数据
                tableData: [],
                //弹出框显示控制（弹出框表单）
                dialogVisible: false,
                //编辑数据（弹出框表单）
                editData: {},
                //是否添加操作（弹出框表单）
                isAdd: false,
                //共有数据条数（分页控件）
                totalCount: 0,
                //每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                //批量操作选中list（分页控件）
                selectList: [],
                //展示搜索区
                showSearch:false,
                //导出loading
                exportLoading: false,
            }
        },
        //生命周期create函数
        created() {
            queryParams.limit=10;
            //创建时获取所有权限数据（分页查询）
            this.handleTableCheck();
            //获取OptionList
            this.getOptionList();
        },
        methods: {
            /*************************************搜索区相关方法（搜索、重置）***********************************/
            /** 搜索按钮操作 */
            handleQuery() {
                queryParams.pageNum = 1;
                this.handleTableCheck();
            },
            /** 重置按钮操作 */
            resetQuery() {
                for(let key of Object.keys(dateRange)){
                    dateRange[key]=[]
                }
                this.$refs['queryForm'].resetFields();
                this.handleQuery();
            },
            /*************************************按键区相关方法(add,export,import)***********************************/
            /** 点击批量删除时(分页组件) */
            handleDelAll(array) {
                removeData("oss","serverGroup",array).then(res => {
                    if (res.code === 200) {
                        //刷新页面数据
                        this.refreshData()
                        //顶部显示提示
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                })
            },
            /** 点击启用禁用时,disabled==true为禁用(分页组件) */
            handleState(disabled,array) {
                //此处0为禁用，1为启用（根据自己代码情况进行调整）
                const state=disabled?0:1;
                let param = {
                    ids:array,
                    state:state
                }
                //更新状态
                updateState("oss","serverGroup",param).then(res => {
                    if (res.code === 200) {
                        //刷新页面数据
                        this.refreshData()
                        //顶部显示提示
                        this.$message({
                            type: 'success',
                            message: '状态修改成功!'
                        });
                    }
                })
            },
            /** 点击添加节点 */
            handleAdd() {
                this.isAdd = true
                this.dialogVisible = true;
            },
            /** 处理导入 */
            handleImport(){

            },
            /** 处理导出 */
            handleExport(){

            },
            /*************************************表格区相关方法(edit,delete)***********************************/
            /** 点击删除操作（表格） */
            clickDelete(data) {
                this.dataDel(data.id);
            },
            /** 点击编辑操作（表格） */
            clickEdit(data) {
                this.isAdd = false
                this.editData = data
                this.dialogVisible = true;
            },
            /** 处理选择改变（表格） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /*************************************其他相关方法（公用等）***********************************/
            /** 刷新页面数据(必须要有，否则子组件会不可用) */
            refreshData() {
                //刷新表格
                this.handleTableCheck()
            },
            /*************************************数据处理方法（转换遍历等）***********************************/
            /** 处理表格查询  */
            handleTableCheck() {
                let param = queryParams;
                for(let key in dateRange){
                    param=this.$setDataRange(param,dateRange[key],key);
                }
                this.getDataPage(param);
            },
            /*************************************数据请求方法（网络请求）***********************************/
            /** 删除  */
            dataDel(id) {
                //弹出确认框
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //确认点击删除后，请求后端
                    removeById("oss","serverGroup",id).then(res => {
                        if (res.code === 200) {
                            //刷新页面数据
                            this.refreshData()
                            //顶部显示提示
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            /** 分页查询（表格）（分页） */
            getDataPage(param) {
                getPage("oss","serverGroup",param).then(res => {
                    if (res.code === 200) {
                        this.tableData = res.data.list;
                        this.totalCount = res.data.totalCount;
                    }
                })
            },
            /** 获取OptionList */
            getOptionList() {
                // getList("manage", "scheduleStrategy").then(res => {
                //     if (res.code === 200) {
                //         options.strategyOptions = res.data;
                //     }
                // })
            },
            /*************************************组件回调事件***********************************/
            /** 当每页展示条数发生变化时，重新请求,页面转回第一页(分页组件) */
            handleSizeChange(pageSize) {
                queryParams.limit = pageSize;
                this.refreshData();
            },
            /** 当当前页发生变化时，重新请求(分页组件) */
            handleCurrentChange(currentPage) {
                queryParams.currPage = currentPage;
                //未选中，全文读取
                this.refreshData();
            }
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');
    ::v-deep .el-form-item{
        margin-bottom: 18px;
    }
</style>
