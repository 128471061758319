<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
                <!-- 右边主要区 -->
                <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                    <!-- 右边头部搜索区 -->
                    <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="110px">
                                <!-- 字典id -->
                                <el-form-item :label="columnInfo.dictionaryId.label" prop="dictionaryId">
                                        <options-select v-model="queryParams.param.dictionaryId" :options="options.dictionaryIdOptions" size="small"></options-select>
                                </el-form-item>
                                <!-- 字典项文本 -->
                                <el-form-item :label="columnInfo.label.label" prop="label">
                                        <el-input v-model="queryParams.param.label" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 字典项值 -->
                                <el-form-item :label="columnInfo.value.label" prop="value">
                                        <el-input v-model="queryParams.param.value" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 回显类型 -->
                                <el-form-item :label="columnInfo.style.label" prop="style">
                                        <enum-dict-select v-model="queryParams.param.style" module-name="system" dict-code="labelStyle"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                                <!-- 备注 -->
                                <el-form-item :label="columnInfo.comment.label" prop="comment">
                                        <el-input v-model="queryParams.param.comment" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 排序号 -->
                                <el-form-item :label="columnInfo.sort.label" prop="sort">
                                        <el-input v-model="queryParams.param.sort" placeholder="请输入内容" size="small" clearable @keyup.enter.native="clickSearch"></el-input>
                                </el-form-item>
                                <!-- 状态 -->
                                <el-form-item :label="columnInfo.state.label" prop="state">
                                        <enum-dict-select v-model="queryParams.param.state" module-name="system" dict-code="state"  placeholder="请选择" size="small" clearable/>
                                </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="clickSearch">搜索</el-button>
                            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <!-- 右边中部功能区 -->
                    <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                        <el-col :span="1.5">
                            <el-button
                                    type="primary"
                                    plain
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="clickAdd($route.params.id)"
                                    v-permissionDisabled="'system:dictionaryItem:create'"
                            >新增</el-button>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-import-plus title="系统字典项表" module-name="system" table-name="dictionaryItem" :permission-code="'system:dictionaryItem:import'"></excel-import-plus>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-export-plus file-name="系统字典项表" module-name="system" table-name="dictionaryItem" :select-list="selectList" :permission-code="'system:dictionaryItem:export'"></excel-export-plus>
                        </el-col>
                      <el-col :span="1.5">
                        <el-button
                            plain
                            size="small"
                            @click="handleBack"
                        >返回<i class="el-icon-refresh-left el-icon--right"></i></el-button>
                      </el-col>
                        <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                                            :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
                    </el-row>
                    <!-- 右边中部表格区 -->
                    <div class="main">
                        <div class="table">
                            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                      @selection-change="handleSelectionChange" height="100%" :header-cell-style="{background:'#ECF5FF'}">
                                <el-table-column align="center" type="selection" width="55"/>
                                        <!-- 字典id -->
<!--                                        <af-table-column align="center" prop="dictionaryId" :label="columnInfo.dictionaryId.label" v-if="columnInfo.dictionaryId.visible">-->
<!--                                            <template v-slot="scope">-->
<!--                                                <option-name-label v-model="scope.row.dictionaryId" :options="options.dictionaryIdOptions"></option-name-label>-->
<!--                                            </template>-->
<!--                                        </af-table-column>-->
                                        <!-- 字典项文本 -->
                                        <af-table-column align="center" prop="label" :label="columnInfo.label.label" v-if="columnInfo.label.visible">
                                        </af-table-column>
                                        <!-- 字典项值 -->
                                        <af-table-column align="center" prop="value" :label="columnInfo.value.label" v-if="columnInfo.value.visible">
                                        </af-table-column>
                                        <!-- 回显类型 -->
                                        <af-table-column align="center" prop="style" :label="columnInfo.style.label" v-if="columnInfo.style.visible">
                                            <template v-slot="scope">
                                                <enum-dict-label v-model="scope.row.style" dict-code="labelStyle" module-name="system" show-tag></enum-dict-label>
                                            </template>
                                        </af-table-column>
                                        <!-- 备注 -->
                                        <af-table-column align="center" prop="comment" :label="columnInfo.comment.label" v-if="columnInfo.comment.visible">
                                        </af-table-column>
                                        <!-- 排序号 -->
                                        <af-table-column align="center" prop="sort" :label="columnInfo.sort.label" v-if="columnInfo.sort.visible">
                                        </af-table-column>
                                        <!-- 状态 -->
                                        <af-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible">
                                            <template v-slot="scope">
                                                <enum-dict-label v-model="scope.row.state" dict-code="state" module-name="system" show-tag></enum-dict-label>
                                            </template>
                                        </af-table-column>
                                <!-- 操作列 -->
                                <el-table-column align="center" label="操作" width="225" fixed="right">
                                    <template v-slot="scope">
                                        <el-button @click="clickEdit(scope.row)" type="primary" size="mini" v-permissionDisabled="'system:dictionaryItem:update'">编辑</el-button>
                                        <el-button @click="clickDel(scope.row)" type="danger" size="mini" v-permissionDisabled="'system:dictionaryItem:remove'">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!-- 右边底部分页区 -->
                    <div style="height: 40px;margin-bottom: 0.1rem;">
                        <pagination-plus :totalCount="totalCount"
                                         :param="queryParams"
                                         :pageSizes="pageSizes"
                                         :selectList="selectList"
                                         module-name="system"
                                         table-name="dictionaryItem"
                                         :del-all-permission-code="'system:dictionaryItem:removes'"
                                         :state-permission-code="'system:dictionaryItem:state'"
                                         @handleTableRefresh="handleTableSearch"/>
                    </div>
                </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <dictionaryItemForm/>
    </div>
</template>

<script>
    /**
     * 系统字典项表主页面 （与自定义组件之间请遵循MVVM架构）
     * @author PeterLee
     * @since 2024-06-13
     */
    import dictionaryItemForm from "./DictionaryItemForm.vue"
    import {mapState, mapMutations, mapActions} from "vuex";
    import {registerStore,unregisterStore} from "@/utils/common";
    import dictionaryItemDataProvider from "./DictionaryItemDataProvider";

    export default {
        components: {
            // 表单弹出组件
            dictionaryItemForm
        },
        data() {
            return {
                // 每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                // 批量操作选中list（分页控件）
                selectList: [],
                // 展示搜索区（right-toolbar）
                showSearch: false,
                // 导出loading
                exportLoading: false,
            }
        },
        computed: {
            ...mapState({
                // 表格数据
                tableData: state => state.system.dictionaryItem.tableData,
                // 下拉选项
                options: state => state.system.dictionaryItem.options,
                // 共有数据条数（分页控件）
                totalCount: state => state.system.dictionaryItem.totalCount,
                // 列信息
                columnInfo: state => state.system.dictionaryItem.columnInfo,
                // 查询参数
                queryParams: state => state.system.dictionaryItem.queryParams,
                // 时间范围
                dateRange: state => state.system.dictionaryItem.dateRange,
                // 更多按钮信息
                moreButtonProps: state => state.system.dictionaryItem.moreButtonProps,
            })
        },
      // 生命周期create函数
        created() {
            // 注册vuex模块
            registerStore('system','dictionaryItem',dictionaryItemDataProvider);
            // 赋值dictionaryId
            this.queryParams.param.dictionaryId = this.$route.params.id;
            // 创建时获取所有权限数据（分页查询）
            this.handleTableSearch();
            // 获取OptionList
            this.getOptionList();
        },
        // 生命周期销毁函数
        destroyed() {
            // 解绑vuex模块
            unregisterStore('system','dictionaryItem');
        },
        methods: {
            ...mapMutations({
                clickEdit: 'system/dictionaryItem/clickEdit',
                clickAdd: 'system/dictionaryItem/clickAdd',
            }),
            ...mapActions({
                clickDel: 'system/dictionaryItem/handleDel',
                handleTableSearch: 'system/dictionaryItem/handleTableSearch',
                getOptionList: 'system/dictionaryItem/getOptionList',
            }),
            /** 重置按钮点击（搜索区） */
            clickReset() {
                for (let key of Object.keys(this.dateRange)) {
                    this.dateRange[key] = []
                }
                this.$refs['queryForm'].resetFields();
                this.queryParams.param.dictionaryId = this.$route.params.id;
                this.handleTableSearch();
            },
            /** 搜索按钮点击（搜索区） */
            clickSearch() {
                this.queryParams.currPage = 1;
                this.handleTableSearch();
            },
            /** 处理选择改变（表格区） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /** 重新计算表格（按钮区） */
            redoTableLayout() {
                // 数据加载完成后执行
                this.$nextTick(() => {
                    // 表格重新渲染
                    this.$refs.multipleTable.doLayout();
                });
            },
            /** 更多按钮方法处理（按钮区） */
            handleCommand(command,row) {
                // console.log(command)
                // console.log(row)
            },
            /** 处理返回 */
            handleBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');

    ::v-deep .el-form-item {
        margin-bottom: 18px;
    }
</style>
