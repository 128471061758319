/**
 * 本文件存放在form表单和index页面中使用的常量数据，作为相关页面的"数据提供者"
 * 参考链接（Alt/Ctrl+单击点开）：https://element.eleme.cn/#/zh-CN/component/form
 * 系统通知内容表数据提供者 作者：PeterLee 时间：2021-09-24
 */
//表单数据校验规则
const rules = {
    type:[{
         required: true,
         message: '请输入通知类型 0：消息，1:通知，2：告警',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    toPath:[{
         required: false,
         message: '请输入前往处理路径',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    backPath:[{
         required: false,
         message: '请输入回执跳转路径',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    title:[{
         required: true,
         message: '请输入通知标题',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    detail:[{
         required: true,
         message: '请输入内容详情（富文本）',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
    state:[{
         required: true,
         message: '请输入状态 0：无效 1：有效',
         trigger: 'blur'
    },
        {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur'
        }
    ],
}
//下拉框选项(没有下拉框选项可以删除，此处只为范例)
const options={

}
export {
    rules,
    options
}
