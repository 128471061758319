<template>
    <div style="height: 100%;">
        <el-row :gutter="20" class="container">
                <!--右边主要区-->
                <el-col :span="24" :xs="24" class="container" style="flex-direction: column;border-width: 0;padding-top: 25px;">
                    <!--右边头部搜索区-->
                    <el-form :model="queryParams.param" ref="queryForm" :inline="true" label-position="right" v-show="showSearch" label-width="110px">
                                <!-- 职务名称 -->
                                <el-form-item :label="columnInfo.name.label" prop="name">
                                        <el-input v-model="queryParams.param.name" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 职位编码 -->
                                <el-form-item :label="columnInfo.code.label" prop="code">
                                        <el-input v-model="queryParams.param.code" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 职务描述 -->
                                <el-form-item :label="columnInfo.description.label" prop="description">
                                        <el-input v-model="queryParams.param.description" placeholder="请输入内容" size="small" clearable></el-input>
                                </el-form-item>
                                <!-- 状态 0：停用 1：启用 -->
                                <el-form-item :label="columnInfo.state.label" prop="state">
                                    <enum-dict-select v-model="queryParams.param.state" dict-code="state" module-name="system" size="small"></enum-dict-select>
                                </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="handleTableSearch">搜索</el-button>
                            <el-button icon="el-icon-refresh" size="small" @click="clickReset">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <!--右边中部功能区-->
                    <el-row :gutter="10" style="margin-bottom:13px;margin-right: 1px">
                        <el-col :span="1.5">
                            <el-button
                                    type="primary"
                                    plain
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="clickAdd"
                                    v-permissionDisabled="'system:position:create'"
                            >新增</el-button>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-import-plus title="系统职务表" module-name="system" table-name="position" :permission-code="'system:position:import'"></excel-import-plus>
                        </el-col>
                        <el-col :span="1.5">
                            <excel-export-plus file-name="系统职务表" module-name="system" table-name="position" :select-list="selectList" :permission-code="'system:position:export'"></excel-export-plus>
                        </el-col>
                        <right-toolbar-plus :showSearch.sync="showSearch" @queryTable="handleTableSearch"
                                            :columnInfo="columnInfo" @redoTableLayout="redoTableLayout"></right-toolbar-plus>
                    </el-row>
                    <!--右边中部表格区-->
                    <div class="main">
                        <div class="table">
                            <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                      @selection-change="handleSelectionChange" height="100%" :header-cell-style="{background:'#ECF5FF'}">
                                <el-table-column align="center" type="selection" width="55"/>
                                        <!-- 职务名称 -->
                                        <af-table-column align="center" prop="name" :label="columnInfo.name.label" v-if="columnInfo.name.visible"></af-table-column>
                                        <!-- 职位编码 -->
                                        <af-table-column align="center" prop="code" :label="columnInfo.code.label" v-if="columnInfo.code.visible"></af-table-column>
                                        <!-- 职务描述 -->
                                        <af-table-column align="center" prop="description" :label="columnInfo.description.label" v-if="columnInfo.description.visible"></af-table-column>
                                        <!-- 状态 0：停用 1：启用 -->
                                        <el-table-column align="center" prop="state" :label="columnInfo.state.label" v-if="columnInfo.state.visible">
                                          <template slot-scope="scope">
                                            <enum-dict-label v-model="scope.row.state" module-name="system" dict-code="state"
                                                             show-tag></enum-dict-label>
                                          </template>
                                        </el-table-column>
                                <!-- 操作列 -->
                                <el-table-column align="center" label="操作" width="240" fixed="right">
                                    <template slot-scope="scope">
                                      <el-button @click="clickEdit(scope.row)" type="primary" size="mini" v-permissionDisabled="'system:position:update'">编辑</el-button>
                                      <el-button @click="clickDel(scope.row)" type="danger" size="mini" v-permissionDisabled="'system:position:remove'">删除</el-button>
                                      <organization-relation-button ref="relationBtn" title="组织关联" show-title="职位信息" @getDescData="getDescData($event,scope.row)"
                                                                      @getRelationIds="getRelationIds($event,scope.row)" @onClickConfirm="onClickConfirm(arguments,scope.row)"
                                      @onClickCancel="onClickCancel">组织分配</organization-relation-button>
<!--                                        <table-more-button :row="scope.row" :button-props="moreButtonProps" @handleCommand="handleCommand"></table-more-button>-->
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!--右边底部分页区-->
                    <div style="height: 40px;margin-bottom: 0.1rem;">
                        <pagination-plus :totalCount="totalCount"
                                         :param="queryParams"
                                         :pageSizes="pageSizes"
                                         :selectList="selectList"
                                         module-name="system"
                                         table-name="position"
                                         :del-all-permission-code="'system:position:removes'"
                                         :state-permission-code="'system:position:state'"
                                         @handleTableRefresh="handleTableSearch"/>
                    </div>
                </el-col>
        </el-row>
        <!-- 弹出层组件 -->
        <PositionForm/>
    </div>
</template>

<script>
    /**
     * 系统职务表主页面 （与自定义组件之间请遵循MVVM架构）
     * @author PeterLee
     * @since 2022-06-23
     */
    import PositionForm from "./PositionForm";
    import {mapState, mapMutations, mapActions} from "vuex";
    import OrganizationRelationButton from "@/views/system/components/OrganizationRelationButton";
    import {getOrganizationIdsByPositionId, positionBatchOrganization} from "@/api/systemApi";
    import {registerStore,unregisterStore} from "@/utils/common";
    import positionStore from './PositionDataProvider'

    export default {
        components: {
            //表单弹出组件
            PositionForm,
            OrganizationRelationButton
        },
        data() {
            return {
                //每页条数选项（分页控件）
                pageSizes: [10, 20, 40, 80],
                //批量操作选中list（分页控件）
                selectList: [],
                //展示搜索区（right-toolbar）
                showSearch: false,
                //导出loading
                exportLoading: false,
            }
        },
        computed: {
            ...mapState({
                //表格数据
                tableData: state => state.system.position.tableData,
                //下拉选项
                options: state => state.system.position.options,
                //共有数据条数（分页控件）
                totalCount: state => state.system.position.totalCount,
                //列信息
                columnInfo: state => state.system.position.columnInfo,
                //查询参数
                queryParams: state => state.system.position.queryParams,
                //时间范围
                dateRange: state => state.system.position.dateRange,
                //更多按钮信息
                moreButtonProps: state => state.system.position.moreButtonProps,
            })
        },
        //生命周期create函数
        created() {
            //注册vuex模块
            registerStore('system','position',positionStore);
            //创建时获取所有权限数据（分页查询）
            this.handleTableSearch();
            //获取OptionList
            this.getOptionList();
        },
        destroyed() {
            unregisterStore('system','position');
        },
        methods: {
            ...mapMutations({
                clickEdit: 'system/position/clickEdit',
                clickAdd: 'system/position/clickAdd',
            }),
            ...mapActions({
                clickDel: 'system/position/handleDel',
                handleTableSearch: 'system/position/handleTableSearch',
                getOptionList: 'system/position/getOptionList',
            }),
            /** 重置按钮点击（搜索区） */
            clickReset() {
                for (let key of Object.keys(this.dateRange)) {
                    this.dateRange[key] = []
                }
                this.$refs['queryForm'].resetFields();
                this.handleTableSearch();
            },
            /** 处理选择改变（表格区） */
            handleSelectionChange(selectList) {
                this.selectList = selectList;
            },
            /** 重新计算表格（按钮区） */
            redoTableLayout() {
                //数据加载完成后执行
                this.$nextTick(() => {
                    //表格重新渲染
                    this.$refs.multipleTable.doLayout();
                });
            },
            /** 更多按钮方法处理（按钮区） */
            handleCommand(command,row) {
                // console.log(command)
                // console.log(row)
            },
            /** 获取关联ids(组织关联组件) */
            getRelationIds(callback,row) {
               // 通过row获取关联组织ids
              getOrganizationIdsByPositionId(row.id).then( res => {
                    if (res.code === 200) {
                      callback(res.data)
                    }
              })
            },
            /** 获取描述数据(组织关联组件) */
            getDescData(callback,row) {
              callback([{
                name: '职位名称',
                value: row.name
              },{
                name: '职位编码',
                value: row.code
             }])
            },
            /** 点击确认按钮，关联组织（组织关联组件）*/
            onClickConfirm([keys,halfKeys,callback],row) {
                keys.unshift.apply(keys,halfKeys);
                // 获取勾选ids,关联组织
                let data = {
                  positionId: row.id,
                  organizationIds: keys
                }
                positionBatchOrganization(data).then(res => {
                  if (res.code === 200) {
                     this.$message.success("组织分配成功");
                     callback();
                  }
                })
            },
            /** 点击取消按钮，关闭窗口（组织关联组件）*/
            onClickCancel(callback) {
                callback()
            },
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/assets/style/index.less');

    ::v-deep .el-form-item {
        margin-bottom: 18px;
    }
</style>
