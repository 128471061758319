<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="系统角色表"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 角色名 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                                <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <!-- 角色标识 -->
                        <el-form-item :label="columnInfo.code.label" prop="code">
                                <el-input v-model="ruleForm.code"></el-input>
                        </el-form-item>
                        <!-- 角色描述 -->
                        <el-form-item :label="columnInfo.description.label" prop="description">
                                <el-input v-model="ruleForm.description"></el-input>
                        </el-form-item>
                        <!-- 角色类型 0默认角色 1自建角色 -->
                        <el-form-item :label="columnInfo.type.label" prop="type">
                            <enum-dict-select v-model="ruleForm.type" module-name="system" dict-code="roleType"></enum-dict-select>
                        </el-form-item>
<!--                        &lt;!&ndash; 数据权限范围 &ndash;&gt;-->
<!--                        <el-form-item :label="columnInfo.dataScope.label" prop="dataScope">-->
<!--                          <enum-dict-select v-model="ruleForm.dataScope" module-name="system" dict-code="dataScope"></enum-dict-select>-->
<!--                        </el-form-item>-->
                        <!-- 状态 0：无效 1：有效 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                            <enum-dict-select v-model="ruleForm.state" module-name="system" dict-code="state"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
    /**
     * 	系统角色表表单
     * 	@author PeterLee
     * 	@since 2022-03-26
     */
    import {mapState, mapMutations, mapActions} from "vuex";

    export default {
        name: 'RoleForm',
        //计算操作
        computed: {
            ...mapState({
                //校验规则
                rules: state => state.system.role.rules,
                //表格数据
                tableData: state => state.system.role.tableData,
                //表单信息
                formInfo: state => state.system.role.formInfo,
                //下拉选项
                options: state => state.system.role.options,
                //列信息
                columnInfo: state => state.system.role.columnInfo,
            })
        },
        methods: {
            ...mapMutations({
                setDialogVisible: 'system/role/setDialogVisible',
            }),
            ...mapActions({
                submitAdd: 'system/role/submitAdd',
                submitEdit: 'system/role/submitEdit',
            })
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
