<template>
  <div style="display: inline;margin: 0 0 0 9px;">
    <el-button @click="clickOrganizationTree" type="success" size="mini" v-permissionDisabled="'system:position:roleList'">
      <slot></slot>
    </el-button>
    <el-dialog v-dialogDrag :title="title" :visible.sync="dialogVisible" width="520px" @close="clickDialogClose" center append-to-body>
      <!-- 信息描述区域 -->
      <el-descriptions :title="showTitle" :column="2" border>
        <el-descriptions-item :label="item.name" v-for="(item,index) in showData" :key="index">
          {{item.value}}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 节点筛选输入框 -->
      <el-input placeholder="输入关键字进行搜索" v-model="filterText" style="margin-bottom: 0.35rem;margin-top: 0.45rem;">
      </el-input>
      <div style="display: flex;">
        <div style="width: 100%;">
          <el-checkbox v-model="expandAll" @change="handleCheckedTreeExpand($event)">展开/折叠</el-checkbox>
          <el-checkbox v-model="checkedAll" @change="handleCheckedTreeNodeAll($event)">全选/全不选</el-checkbox>
          <el-checkbox v-model="checkStrictly">父子联动</el-checkbox>
          <el-tree :data="treeData" show-checkbox default-expand-all node-key="id" ref="tree" class="tree-border" style="overflow: auto;height: 5rem;"
                   :check-strictly="!checkStrictly"
                   :filter-node-method="filterNode"
                   empty-text="没有相关组织信息"
                   :props="defaultProps">
          </el-tree>
        </div>
      </div>
      <!-- 底部按钮区 -->
      <template #footer class="dialog-footer">
			  <el-button type="primary" @click="clickConfirm">确 定</el-button>
			  <el-button @click="clickCancel">取 消</el-button>
		  </template>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 组织关联按钮
 * @author PeterLee
 * @date 2022-06-28
 * 使用范例：<organization-relation-button ref="relationBtn" title="组织关联"
 *   show-title="职位信息" @getDescData="getDescData($event,scope.row)" @getRelationIds="getRelationIds($event,scope.row)" @onClickConfirm="onClickConfirm(arguments,scope.row)" @onClickCancel="onClickCancel">
 *   组织分配</organization-relation-button>
 * 使用方法：
 *   1.@getDescData中获取描述区的内容，callback([{name:'',value:''}])
 *   2.@getRelationIds中获取关联组织ids,callback(ids)
 *   3.@onClickConfirm中callback()回调设置弹出框消失
 *   4.@onClickCancel,@onClickClose都是用callback()回调设置弹出框消失
 */
import {getList} from "@/api/baseApi";
import {handleTree} from "@/utils/common";

export default {
  // 父组件传入参数
  props: {
    // 弹出框标题
    title: {
      type: String,
      default : "组织关联"
    },
    // 展示标题
    showTitle: {
      type: String,
      default : "职位信息"
    },
  },
  // 计算操作
  computed: {},
  data() {
    return {
      // 组件内部弹出框展示与否
      dialogVisible: false,
      // 树默认映射
      defaultProps: {
        children: 'childrenList',
        label: 'name'
      },
      // 组织树数据
      treeData: [],
      // 展开所有
      expandAll: true,
      // 父子联动
      checkStrictly: true,
      // 全选
      checkedAll: false,
      // 节点过滤文本
      filterText: "",
      // 组织关联弹出框显示信息（弹出组织树组件）
      showData: [
        {
          name: '名称',
          value: 'simple'
        }
      ]
    }
  },
  watch: {
    //节点过滤监听
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    // 点击数据权限按钮
    clickOrganizationTree() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$emit('getRelationIds', (checkedIds) => {
          if (checkedIds!==null&&checkedIds!==undefined) {
            checkedIds.forEach(item => {
              const node = this.$refs.tree.getNode(item);
              if (node !==null && node !== undefined) {
                this.$refs.tree.setChecked(node, true);
              }
            });
          }
        })
      });
    },
    // 点击取消按钮
    clickCancel() {
      this.$emit('onClickCancel',()=> {
        this.dialogVisible = false;
      });
    },
    // 关闭弹窗时
    clickDialogClose() {
      this.$emit('onClickClose',()=> {
        this.dialogVisible = false;
      });
    },
    // 确认返回选中的组织信息
    clickConfirm() {
      // 目前被选中的部门节点
      let checkedKeys = this.$refs.tree.getCheckedKeys();
      // 半选中的部门节点
      let halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();
      // checkedKeys.unshift.apply(checkedKeys,halfCheckedKeys);
      this.$emit('onClickConfirm',checkedKeys,halfCheckedKeys,() =>{
        this.dialogVisible = false;
      });
    },
    // 查询组织树
    getOrganizationTree() {
      getList("system", "organization").then(res => {
        if (res.code === 200) {
          this.treeData =  handleTree(res.data,'id','parentId','childrenList');
        }
      })
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value) {
      let treeList = this.treeData;
      for (let i = 0; i < treeList.length; i++) {
        this.$refs.tree.store.nodesMap[treeList[i].id].expanded = value;
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value) {
      this.$refs.tree.setCheckedNodes(value ? this.treeData : []);
    },
    //节点过滤方法
    filterNode(value, data) {
      if (!value) return true;
      return data[this.defaultProps.label].indexOf(value) !== -1;
    },
  },
  created() {
    //获取描述信息
    this.$emit('getDescData',(data) => {
      this.showData = data;
    });
    // 创建时获取组织树
    this.getOrganizationTree()
  },
  //
  mounted() {

  },
  // 生命周期updated执行时
  updated() {

  }
}
</script>

<style lang="less" scoped>
/* tree border */
.tree-border {
  margin-top: 15px;
  border: 1px solid #e5e6e7;
  background: #FFFFFF none;
  border-radius: 4px;
}
::v-deep .el-dialog__header {
  padding: 6px 20px;
}
::v-deep .el-dialog__footer {
  padding: 0 20px 30px;
}
@import '~@/styles/el-dialog-common.less';
</style>
