<template>
    <div>
        <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
        <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="系统职务表"
                  :showData="formInfo.editData"
                  :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
            <!-- 插槽部分 -->
            <template v-slot:default="{ruleForm}" class="info">
                        <!-- 职务名称 -->
                        <el-form-item :label="columnInfo.name.label" prop="name">
                                <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <!-- 职位编码 -->
                        <el-form-item :label="columnInfo.code.label" prop="code">
                                <el-input v-model="ruleForm.code"></el-input>
                        </el-form-item>
                        <!-- 职务描述 -->
                        <el-form-item :label="columnInfo.description.label" prop="description">
                                <el-input v-model="ruleForm.description"></el-input>
                        </el-form-item>
                        <!-- 状态 0：停用 1：启用 -->
                        <el-form-item :label="columnInfo.state.label" prop="state">
                            <enum-dict-select v-model="ruleForm.state" dict-code="state" module-name="system"></enum-dict-select>
                        </el-form-item>
            </template>
        </base-form-plus>
    </div>
</template>

<script>
    /**
     * 	系统职务表表单
     * 	@author PeterLee
     * 	@since 2022-06-23
     */
    import {mapState, mapMutations, mapActions} from "vuex";

    export default {
        name: 'PositionForm',
        //计算操作
        computed: {
            ...mapState({
                //校验规则
                rules: state => state.system.position.rules,
                //表格数据
                tableData: state => state.system.position.tableData,
                //表单信息
                formInfo: state => state.system.position.formInfo,
                //下拉选项
                options: state => state.system.position.options,
                //列信息
                columnInfo: state => state.system.position.columnInfo,
            })
        },
        methods: {
            ...mapMutations({
                setDialogVisible: 'system/position/setDialogVisible',
            }),
            ...mapActions({
                submitAdd: 'system/position/submitAdd',
                submitEdit: 'system/position/submitEdit',
            })
        }
    }
</script>

<style lang="less" scoped>
    .info {
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            width: 50%;
        }
    }
</style>
