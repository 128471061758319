import { render, staticRenderFns } from "./TableMenuCreateButton.vue?vue&type=template&id=03b184cf&scoped=true"
import script from "./TableMenuCreateButton.vue?vue&type=script&lang=js"
export * from "./TableMenuCreateButton.vue?vue&type=script&lang=js"
import style0 from "./TableMenuCreateButton.vue?vue&type=style&index=0&id=03b184cf&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b184cf",
  null
  
)

export default component.exports