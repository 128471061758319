<template>
  <div style="display: inline;margin: 0 0 0 9px;">
    <!-- 按钮 -->
    <el-button :type="disabled?'info':'success'" :disabled="disabled" size="mini" @click="clickOpen">
      <slot></slot>
    </el-button>
    <el-dialog v-dialogDrag="true" title="权限扫描结果" :visible.sync="dialogVisible" width="800px" @close="clickClose" append-to-body top="70px">
      <div class="container">
        <!-- 扫描结果表 -->
        <el-table
            :data="tableData"
            height="622"
            border ref="multipleTable"
            @selection-change="handleSelectionChange"
            style="width: 100%;" :header-cell-style="{background:'#ECF5FF'}">
          <el-table-column align="center" type="selection" width="55" :selectable="getSelectable"/>
          <el-table-column
              prop="name"
              label="权限名称"
              width="277">
          </el-table-column>
          <el-table-column
              prop="code"
              label="权限码"
              width="288">
          </el-table-column>
          <el-table-column
              prop="exist"
              label="存在菜单"
              width="88">
            <template v-slot="scope">
              <enum-dict-label v-model="scope.row.exist" dict-code="binary" module-name="data"
                               show-tag></enum-dict-label>
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex;justify-content: center;">
          <el-button type="primary" style="margin-top: 36px;" @click="clickAddMenu">添加菜单</el-button>
          <el-button style="margin-top: 36px;" @click="clickClose">关闭页面</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getPermissionScan} from "@/api/baseApi";
import {scanMenuCreate, updatePermissionExist} from "@/api/systemApi";

export default {
  name: "PermissionScan",
  props: {
     row : {
       type:Object,
       default: {}
     },
     disabled: {
       type:Boolean,
       default: false
     }
  },
  data() {
    return {
      tableData:[],
      dialogVisible:false,
      selectList:[],
    }
  },
  created() {

  },
  methods: {
    // 获取可勾选与否
    getSelectable(row) {
       return !row.exist;
    },
    // 关闭弹窗时
    clickDialogClose() {
      // this.$emit("update:relationDialogVisible", false);
    },
    // 点击打开
    clickOpen() {
      const moduleName = this.row.path;
      if (moduleName === null || moduleName === '' || moduleName === undefined) {
        this.$message.warning("缺少模块名，不能进行权限扫描！")
        return;
      }
      this.getOrRefreshTable(moduleName)
    },
    // 获取或刷新表格内容
    getOrRefreshTable(moduleName) {
      getPermissionScan(moduleName).then(res=> {
        if (res.code === 200) {
          // 根据data获取是否存在于菜单
          // 将该结果上传至后台，后台进行解析，回填，填充完毕之后渲染回表格（前端来做耦合）
          updatePermissionExist(res.data).then(res => {
            if (res.code === 200) {
              this.dialogVisible = true;
              this.tableData = res.data;
            }
          }).catch((res) => {
            this.$message.error("请求异常("+res.status+")，系统模块回填信息失败！")
          })
        }
      }).catch((res) => {
        this.$message.error("请求异常("+res.status+")，该模块可能未正常启动！")
      })
    },
    // table选择事件
    handleSelectionChange(selectList) {
      this.selectList = selectList;
    },
    // 点击添加菜单
    clickAddMenu() {
      if (this.selectList !== null && this.selectList !== undefined && this.selectList.length !== 0) {
        scanMenuCreate(this.selectList).then(res=>{
          if (res.code === 200) {
            // 待完成
            this.$message.success("生成完成，请查验核对")
            // 刷新表格
            const moduleName = this.row.path;
            this.getOrRefreshTable(moduleName)
          }
        })
      } else {
        this.$message.info("请先勾选需要生成的内容")
      }
    },
    // 点击关闭
    clickClose() {
      this.dialogVisible = false;
      this.$emit('clickClose')
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .el-dialog {
  border-radius: 6px;
}

::v-deep .el-dialog__title {
  font-weight: bold;
  font-size: 0.4rem;
}

::v-deep .el-dialog__close {

}

::v-deep .el-dialog__body {
  padding: 20px;
}

::v-deep .el-dialog--center {
  display: flex;

.el-dialog {
  margin-top: 0;
}
}

::v-deep .el-dialog__header {
  padding: 16px 0 0;
  height: 52px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
}

::v-deep .el-dialog__headerbtn {

}

::v-deep .el-dialog__headerbtn i {
  font-size: 0.4rem;
}

.container {
  height: 100%;
  overflow: auto;
}
</style>