<template>
  <el-container class="container">
    <el-main class="flex flex1 flex-wrap main">
      <div v-for="count in 10" class="card flex flex-column">
        <div class="card_title">
          系统模块
        </div>
        <div class="">
          11 模块管理页面，模块与用户绑定对应，用户和路径，用户名等一体
          nacos
          数据源（按钮绑定）模块绑定数据源，一对多
          代码生成（按钮跳转到表）
          自动部署（按钮）
          自动测试（按钮）
          模块配置（按钮）
        </div>
      </div>
    </el-main>
    <el-aside width="372px">
      aside
      用户信息
      项目主要路径
      登录名等
      用户配置（按钮）
      环境快速统一
      基础包和模板管理
    </el-aside>
  </el-container>
</template>

<script>
export default {
  name: "WorkSpace"
}
</script>

<style lang="less" scoped>
  .container {
    height: 100%;
    border: solid #dcdfe6 1px;
    border-radius: 8px;
    .main {
      height: 100%;
      align-content: flex-start;
      overflow:auto;
      border-right: solid #dcdfe6 1px;
      border-radius: 8px;
      padding-left: 2px;
      padding-top: 10px;
      padding-bottom: 8px;
      .card {
        height: 230px;
        width: 18%;
        background: #efefef;
        margin-bottom: 8px;
        margin-left: 8px;
        border-radius: 8px;
        padding: 4px;
        .card_title {
          font-weight: bold;
          font-size: medium;
          margin: 10px 4px 4px 16px;
        }
      }
    }
    .aside {
      border-left: solid #dcdfe6 1px;
      border-radius: 8px;
    }
  }
</style>