<!-- 数据库表sql执行表单 作者：PeterLee 时间：2021-12-27 -->
<!-- 数据库表信息表单 作者：PeterLee 时间：2021-11-26 -->
<template>
  <div>
    <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
    <BaseForm :isAdd="true" :relationDialogVisible.sync="dialogVisible" showTitle="sql语句执行" :showData="showData"
              :rules="rules" @submitAdd="submitExecute" @closeDialog="closeDialog"
              ref="baseForm" defaultEmpty>
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <el-form-item label="执行sql语句" prop="sql">
          <el-input v-model="ruleForm.sql"></el-input>
        </el-form-item>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import BaseForm from '@/components/widget/BaseForm';
import state from "../TableState";
import {handleEachTable} from "@/api/dataApi";

export default {
  name: 'SqlExecuteForm',
  components: {
    BaseForm
  },
  // 父组件传入参数
  props: {
    // 唤醒弹出框
    relationDialogVisible: Boolean,
    // 勾选表格数据
    showData: Object,
  },
  // 计算操作
  computed: {},
  data() {
    return {
      // 组件内部弹出框展示与否
      dialogVisible: false,
      // 表单校验规则
      rules: state.rules,
      // 选项
      options: state.options,
      // 列信息
      columnInfo: state.columnInfo,
    }
  },
  watch: {
    // 监视父组件是否有指令需要重置页面
    relationDialogVisible: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          // 更新时刷新表单校验信息
          this.dialogVisible = true;
          // 更新回显数据
          this.$nextTick(() => {

          });
        }
      }
    }
  },
  created() {

  },
  methods: {
    // 添加提交后执行内容
    submitExecute(data) {
      this.showData.baseId = this.$route.params.id;
      this.showData.sql = data.sql;
      handleEachTable(this.showData).then(res => {
        if (res.code === 200) {
          // 关闭弹出层并刷新树
          this.$message.success("执行sql语句成功")
          this.$refs.baseForm.setVisible(false);
        }
      });
    },
    // 关闭弹出层
    closeDialog() {
      this.$emit("update:relationDialogVisible", false);
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>
