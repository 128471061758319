<template>
  <div>
    <!-- 注：添加操作时，当左边没有树的值传入时，添加需要默认清空内容defaultEmpty，但包含树图或有内容传入时需要去掉该属性 -->
    <base-form-plus :isAdd="formInfo.isAdd" :relationDialogVisible.sync="formInfo.dialogVisible" showTitle="数据组件表	"
                    :showData="formInfo.editData"
                    :rules="rules" @submitAdd="submitAdd" @submitEdit="submitEdit" ref="baseForm" defaultEmpty>
      <!-- 插槽部分 -->
      <template v-slot:default="{ruleForm}" class="info">
        <!-- 组件名称 -->
        <el-form-item :label="columnInfo.name.label" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <!-- 组件模板代码 -->
        <el-form-item :label="columnInfo.word.label" prop="word">
          <el-input v-model="ruleForm.word" type="textarea"></el-input>
        </el-form-item>
        <!-- 组件类型 -->
        <el-form-item :label="columnInfo.type.label" prop="type">
          <enum-dict-select v-model="ruleForm.type" module-name="data" dict-code="widgetType"></enum-dict-select>
        </el-form-item>
        <!-- 组件备注 -->
        <el-form-item :label="columnInfo.comment.label" prop="comment">
          <el-input v-model="ruleForm.comment"></el-input>
        </el-form-item>
        <!-- 是否默认 -->
        <el-form-item :label="columnInfo.isDefault.label" prop="isDefault">
          <enum-dict-select v-model="ruleForm.isDefault" module-name="data" dict-code="binary"></enum-dict-select>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item :label="columnInfo.state.label" prop="state">
          <enum-dict-select v-model="ruleForm.state" module-name="data" dict-code="state"></enum-dict-select>
        </el-form-item>
      </template>
    </base-form-plus>
  </div>
</template>

<script>
/**
 *  数据组件表  表单
 *  @author PeterLee
 *  @since 2022-07-21
 */
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'WidgetForm',
  // 计算操作
  computed: {
    ...mapState({
      // 校验规则
      rules: state => state.data.widget.rules,
      // 表格数据
      tableData: state => state.data.widget.tableData,
      // 表单信息
      formInfo: state => state.data.widget.formInfo,
      // 下拉选项
      options: state => state.data.widget.options,
      // 列信息
      columnInfo: state => state.data.widget.columnInfo,
    })
  },
  methods: {
    ...mapMutations({
      setDialogVisible: 'data/widget/setDialogVisible',
    }),
    ...mapActions({
      submitAdd: 'data/widget/submitAdd',
      submitEdit: 'data/widget/submitEdit',
    })
  }
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
  }
}
</style>
